import { NumberInput, SimpleForm, TextInput, required } from "react-admin";
import { B2BRequestCreateInput, B2BRequestUpdateInput } from "./types";

interface B2BUpsertB2BRequestFormProps {
  onSubmit: (input: B2BRequestUpdateInput | B2BRequestCreateInput) => void;
  isUpdate: boolean;
}

export const UpsertB2BRequestForm = (props: B2BUpsertB2BRequestFormProps) => {
  const { onSubmit, isUpdate } = props;

  return (
    <SimpleForm
      onSubmit={async ({ ...formValues }) => {
        await onSubmit(formValues as B2BRequestCreateInput);
        return;
      }}
      sx={{ width: "50%" }}
    >
      <NumberInput
        label="API user ID"
        source="b2BUserId"
        validate={[required()]}
        fullWidth
      />
      <TextInput source="sourcePath" validate={[required()]} fullWidth />
      <TextInput
        label="Request parameters (JSON)"
        source="params"
        defaultValue={JSON.stringify({
          do_auto_enhance: true,
          do_apply_preset: false,
          do_cropping: true,
          do_blanket_retouch: true,
          do_face_retouch: true,
          do_rotate: true,
          rotate_degrees: 45,
          do_scale_to_original_size: false,
          debug: false,
        })}
        fullWidth
      />
    </SimpleForm>
  );
};
