import React, {useState} from "react";

import Group from '@mui/icons-material/Group';
import {Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {useMutation, useQueryClient} from "react-query";
import {useNotify} from "react-admin";
import {useDataProvider} from "ra-core";

export type RedoGroupingActionProps = {
    record: any;
}

export const RedoGroupingAction = ({record}: RedoGroupingActionProps) => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const [onlyUngroup, setOnlyUngroup] = useState<boolean>(false);

    const {mutateAsync: redoGroupingEvent, isLoading: isLoadingDeleteEvent} = useMutation(() => {
        const eventId = record.eventId;
        if (!eventId) {
            return;
        }
        return dataProvider.saPost(`Event/${eventId}/RedoGrouping?OnlyUngroup=${onlyUngroup}`, {});
    }, {
        onSuccess: data => {
            notify("Event " + record.eventId + " redo grouping succeeded", {type: 'success'});
        },
        onError: () => {
            notify("Event " + record.eventId + " redo grouping failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Event');
        }
    });

    const handleClick = () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await redoGroupingEvent();
        return;
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        return;
    }

    return (
        <>
            <Tooltip title="Redo grouping">
                {
                    isLoadingDeleteEvent ? <CircularProgress/> : (
                        <IconButton onClick={handleClick}>
                            <Group/>
                        </IconButton>
                    )
                }
            </Tooltip>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Redo grouping on event " + record.eventId + "?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to ungroup all groups and redo Grouping ?
                    </DialogContentText>
                    <FormControlLabel label="Only ungroup, don't re-group" control={
                        <Checkbox
                            color="primary"
                            checked={onlyUngroup}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOnlyUngroup(event.target.checked)}
                        ></Checkbox>
                    }></FormControlLabel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
