import {Create, SimpleForm, TextInput, useNotify, useRedirect, required, BooleanInput} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";
import { StripePrice } from "./types";

export const StripePriceCreate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {
        mutateAsync: addStripePrice,
        isLoading: isLoadingAddStripePrice
    } = useMutation(({priceId, priceName, isOffered}: { priceId: string, priceName: string, isOffered:boolean }) => {
        return dataProvider.saPut(`StripePrices?PriceId=${priceId}&PriceName=${priceName}&IsOffered=${isOffered}`);
    }, {
        onSuccess: async (response: {data: { payload: StripePrice}}) => {
            notify(`Stripe price ${response.data.payload.priceId} created successfully`, {type: 'success'});
            redirect('list', 'StripePrices');
        },
        onError: (data) => {
            notify("Stripe price addition failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`StripePrices`);
        }
    });

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Create stripe price
            </Typography>
            <SimpleBackdrop open={isLoadingAddStripePrice}/>
            <Create>
                <SimpleForm onSubmit={async (formValues) => {
                    if (!formValues.priceId) {
                        notify("Name is required", {type: 'error'});
                        return;
                    }
                    if (!formValues.priceName) {
                        notify("Value is required", {type: 'error'});
                        return;
                    }
                    await addStripePrice({
                        priceId: formValues.priceId,
                        priceName: formValues.priceName,
                        isOffered: formValues.isOffered,
                    });
                }}>
                    <TextInput label="Price id" source="priceId" validate={[required()]} fullWidth/>
                    <TextInput label="Price name" source="priceName" validate={[required()]} fullWidth/>
                    <BooleanInput label="offered ?" source="isOffered" fullWidth/>
                </SimpleForm>
            </Create>
        </>
    );
};
