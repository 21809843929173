import {FunctionField} from "react-admin";
import {DeleteSystemParameterAction} from "./DeleteSystemParameterAction";

export const SystemParametersActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: any) => {
                    return (
                        <>
                            <DeleteSystemParameterAction record={record}/>
                        </>
                    );
                }
            }
        />
    );
};
