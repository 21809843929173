import {
  BooleanInput,
  Datagrid,
  List,
  Pagination,
  TextField,
  TextInput,
} from "react-admin";
import { useImageProcessingStageValues } from "../User/useImageProcessingStageValues";
import {
  ImageProcessingStage,
  useImageProcessingStages,
} from "../User/useImageProcessingStages";
import { EventProcessingStageActionField } from "./EventProcessingStageActionField";
import { EventImageProcessing } from "./types";

const eventProcessingListFilters = [
  <TextInput label="Image id" source="ImageId" alwaysOn />,
  <TextInput label="Event id" source="EventId" alwaysOn />,
  <BooleanInput label="Completed?" source="Completed" />,
];

function processingCompleted(eip: EventImageProcessing): boolean {
  return (
    eip.alignStage !== 0 &&
    eip.autoEnhanceStage !== 0 &&
    eip.cullStage !== 0 &&
    eip.editStage !== 0 &&
    eip.faceRecStage !== 0 &&
    eip.groupStage !== 0 &&
    eip.uploadStage !== 0 &&
    eip.adjustStage !== 0 &&
    eip.rawConvertStage !== 0
  );
}

export const EventProcessingList = () => {
  const { imageProcessingStages } = useImageProcessingStages();

  useImageProcessingStageValues();

  return (
    <>
      <List
        empty={false}
        title="Event Image Processing"
        queryOptions={{
          meta: {
            pagination: true,
            dataExtractFunction: (
              data: {
                payload: { results: EventImageProcessing[] };
              },
              query: { Completed: boolean }
            ) => {
              const results = data.payload.results;
              const completed = query.Completed;
              if (completed !== undefined) {
                return results.filter((eip: EventImageProcessing) => {
                  return completed === processingCompleted(eip);
                });
              }
              return results;
            },
            overridePath: `Event/ImageProcessingStage`,
          },
          refetchOnMount: true,
        }}
        perPage={25}
        pagination={
          <Pagination rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]} />
        }
        filters={eventProcessingListFilters}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField sortable={false} label="Event ID" source="eventId" />
          <TextField sortable={false} label="Image ID" source="imageId" />
          <TextField sortable={false} label="Name" source="name" />

          {imageProcessingStages.map(
            (imageProcessingStage: ImageProcessingStage) => (
              <EventProcessingStageActionField
                source={imageProcessingStage.name}
                stage={imageProcessingStage}
              ></EventProcessingStageActionField>
            )
          )}
        </Datagrid>
      </List>
    </>
  );
};
