import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";

interface ImageURLFieldProps {
  imageURL: string;
}

export const ImageURLField = (props: ImageURLFieldProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = async () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <img
        src={props.imageURL}
        alt={props.imageURL}
        style={{
          height: "50px",
          cursor: "pointer",
          objectFit: "cover",
          border: "3px solid #ccc",
        }}
        onMouseOver={openModal}
        onMouseOut={closeModal}
      />

      <Modal
        open={!!modalIsOpen}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        style={{
          pointerEvents: "none",
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height="100vh"
        >
          <img
            src={props.imageURL} // Display the original image here
            alt={props.imageURL}
            style={{
              height: "90vh",
              objectFit: "contain",
              border: "3px solid #ccc",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
