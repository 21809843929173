import React, {useState} from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";

export type RemoveEventUserActionProps = {
    eventId: number;
    record: any;
}

export const RemoveEventUserAction = ({record, eventId}: RemoveEventUserActionProps) => {
    const notify = useNotify();

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const {mutateAsync: removeEventUser, isLoading: isLoadingRemoveEventUser} = useMutation(() => {
        if (!record.userId) {
            return;
        }
        return dataProvider.saDelete(`Event/${eventId}/RemoveUser?UserId=${record.userId}`, {});
    }, {
        onSuccess: (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`User removal failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("User removed successfully", {type: 'success'});
            }
        },
        onError: () => {
            notify("User removal failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`Event`);
        }
    });

    const handleDelete = () => {
        setDialogIsOpen(true);
    };

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await removeEventUser();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
    }

    if (isLoadingRemoveEventUser) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`Remove User From Event`}>
                <IconButton onClick={handleDelete}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Remove User From Event?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this user from the event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

