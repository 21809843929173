import React, {useState, useRef, useEffect} from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {UserPickerType} from "../../../../types";
import {UserPicker} from "../../../Inputs";
import {getFullName} from "../../../../utils";
import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";

export type EquipmentAssignmentActionProps = {
    record: any;
}

export const AssignUser = ({record}: EquipmentAssignmentActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedUser, setSelectedUser] = useState<UserPickerType | null>(null);
    const currentUser = useRef<UserPickerType | null>(null);
    const unAssignResponse = useRef<any>(null);
    const [assignDialogIsOpen, setAssignDialogIsOpen] = useState<boolean>(false);
    const [deAssignDialogIsOpen, setDeAssignDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (record?.user) {
            currentUser.current = {label: getFullName(record.user), value: record.user.userId};
        }
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: assignUser, isLoading: isLoadingAssignUser } = useMutation(() => {
        if (!selectedUser) {
            return;
        }
        return dataProvider.saPost(`Equipment/${record.equipmentId}/Assign?UserId=${selectedUser?.value}`, {});
    }, {
        onSuccess: data => {
            notify("User assigned successfully", { type: 'success' });
        },
        onError: () => {
            notify("User Assignment failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    const { mutateAsync: deAssignUser, isLoading: isLoadingDEAssignUser } = useMutation(() => {
        if (!record.user.userId) {
            return;
        }
        return dataProvider.saDelete(`Equipment/${record.equipmentId}/Unassign?UserId=${record.user.userId}`, {});
    }, {
        onSuccess: data => {
            notify("User de-assigned successfully", {type: 'success'});
        },
        onError: () => {
            notify("User de-assignment failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    const { mutateAsync: unAssignUser, isLoading: isLoadingUnAssignUser } = useMutation(() => {
        if (!selectedUser) {
            return;
        }
        return dataProvider.saDelete(`Equipment/${record.equipmentId}/Unassign?UserId=${currentUser.current?.value}`, {});
    }, {
        onSuccess: data => {
            unAssignResponse.current = data;
        },
        onError: () => {
            notify("There was an error, canceling operation.", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelect = (user: UserPickerType) => {
        setSelectedUser(user);
    }

    // ASSIGN + REASSIGN
    const assignUserOp = async (override: boolean) => {
        if (!selectedUser) {
            return;
        }
        if (override) {
            await unAssignUser();
            if (((unAssignResponse.current.data) as {code: number}).code === 0) {
                await assignUser();
            }
            return;
        }
        await assignUser();
    }

    const onAssign = async () => {
        if (record.user) {
            setAssignDialogIsOpen(true);
            return;
        }
        await assignUserOp(false);
        handleClose();
    }

    const handleDialogAssignOk = async () => {
        setAssignDialogIsOpen(false);
        await assignUserOp(true);
        handleClose();
    }

    const handleDialogAssignClose = () => {
        setAssignDialogIsOpen(false);
        handleClose();
    }

    // DE-ASSIGN
    const deAssignUserOp = async () => {
        if (!record.user) {
            return;
        }
        await deAssignUser();
    }

    const onDeAssign = async () => {
        setDeAssignDialogIsOpen(true);
        return;
    }

    const handleDialogDeAssignOk = async () => {
        setDeAssignDialogIsOpen(false);
        await deAssignUserOp();
        handleClose();
    }

    const handleDialogDeAssignClose = () => {
        setDeAssignDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingAssignUser || isLoadingUnAssignUser || isLoadingDEAssignUser) {
        return <CircularProgress />
    }

    return (
        <>
            <Tooltip title={`${!record.user ? 'Set' : 'Edit'} User`}>
                <IconButton onClick={handleOpen}>
                    {
                        !record.user ? <AddIcon/> : <EditIcon/>
                    }
                </IconButton>
            </Tooltip>
            <Popover
                id={record.equipmentId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 320,
                    height: 100,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <UserPicker
                        value={record.user ? {
                            label: `${getFullName(record.user)} (${record.user.userId})`,
                            value: record.user.userId
                        } : null}
                        onSelect={onSelect}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            width: '100%'
                        }}
                        >
                    <Button
                        disabled={!selectedUser}
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onAssign}
                    >
                        Save
                    </Button>
                    <Button
                        disabled={!record.user}
                        variant="outlined"
                        startIcon={<DeleteIcon/>}
                        onClick={onDeAssign}
                        color={'error'}
                    >
                        De-Assign User
                    </Button>
                    </Box>
                </Box>
            </Popover>
            <Dialog
                open={assignDialogIsOpen}
                onClose={handleDialogAssignClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Override User?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to override the user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogAssignClose}>Cancel</Button>
                    <Button onClick={handleDialogAssignOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deAssignDialogIsOpen}
                onClose={handleDialogDeAssignClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"De-Assign User?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to de-assign this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeAssignClose}>Cancel</Button>
                    <Button onClick={handleDialogDeAssignOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

