import {AppBar as RAAppBar} from "react-admin";
import {AppPicker} from "../AppPicker";
import {Typography, Container} from "@mui/material";

export const AppBar = (props: any) => (
    <RAAppBar
        sx={{
            "& .RaAppBar-toolbar": {
                width: "100%",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
            },
        }}
        {...props}
    >
        <Container
            sx={{
                margin: 0,
                padding: 0,
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                maxWidth: "initial !important",
            }}
        >
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
            />
            <AppPicker/>
        </Container>
        Version {process.env.REACT_APP_VERSION}
    </RAAppBar>
);
