import {FunctionField} from "react-admin";
import {DeleteLightroomPresetAction} from "./DeleteLightroomPresetAction";
import { ActivateLightroomPresetAction } from "./ActivateLightroomPresetAction";
import { LightroomPreset } from "./types";
import { EditLightroomPresetAction } from "./EditLightroomPresetAction";

export const ActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: LightroomPreset) => {
                    return (
                        <>
                            <ActivateLightroomPresetAction record={record}/>
                            <EditLightroomPresetAction record={record}/>
                            <DeleteLightroomPresetAction record={record}/>
                        </>
                    );
                }
            }
        />
    );
};
