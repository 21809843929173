import {Create, SimpleForm, TextInput, useNotify, useRedirect, required} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";
import { SystemParameter } from "./types";

export const SystemParameterCreate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {
        mutateAsync: addSystemParameter,
        isLoading: isLoadingAddSystemParameter
    } = useMutation(({paramName, paramValue}: { paramName: string, paramValue: string }) => {
        return dataProvider.saPut(`SystemParameters?paramName=${paramName}&paramValue=${paramValue}`);
    }, {
        onSuccess: async (response: {data: { payload: SystemParameter}}) => {
            notify(`System parameter ${response.data.payload.paramName} = ${response.data.payload.paramValue} created successfully`, {type: 'success'});
            redirect('list', 'SystemParameters');
        },
        onError: (data) => {
            notify("System parameter addition failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`SystemParameters`);
        }
    });

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Create system parameter
            </Typography>
            <SimpleBackdrop open={isLoadingAddSystemParameter}/>
            <Create>
                <SimpleForm onSubmit={async (formValues) => {
                    if (!formValues.paramName) {
                        notify("Name is required", {type: 'error'});
                        return;
                    }
                    if (!formValues.paramValue) {
                        notify("Value is required", {type: 'error'});
                        return;
                    }
                    await addSystemParameter({
                        paramName: formValues.paramName,
                        paramValue: formValues.paramValue
                    });
                }}>
                    <TextInput source="paramName" validate={[required()]} fullWidth/>
                    <TextInput source="paramValue" validate={[required()]} fullWidth/>
                </SimpleForm>
            </Create>
        </>
    );
};
