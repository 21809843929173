import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {SimpleForm, TextInput, useNotify} from "react-admin";
import Typography from "@mui/material/Typography";
import { LightroomPreset } from "./types";
import { LightroomPresetStatusSelectInput } from "../../Inputs/LightroomPresetStatusSelectInput";
import { useLightroomPresetStatuses } from "../User/useLightroomPresetStatuses";

export type EditLightroomPresetActionProps = {
    record: LightroomPreset;
};

export const EditLightroomPresetAction = ({record}: EditLightroomPresetActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentLightroomPresetName, setCurrentLightroomPresetName] = useState<string>(record.name);
    const [currentLightroomPresetStatus, setcurrentLightroomPresetStatus] = useState<number>(record.status);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const {getLightroomPresetStatus} = useLightroomPresetStatuses();

    useEffect(() => {
        setCurrentLightroomPresetName(record.name);
        setcurrentLightroomPresetStatus(record.status);
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: updateLightroomPreset, isLoading: isLoadingUpdateLightroomPreset } = useMutation(() => {
        return dataProvider.saPost(`LightroomPresets/${record.lightroomPresetId}?Name=${encodeURIComponent(currentLightroomPresetName)}&Status=${currentLightroomPresetStatus}`, {});
    }, {
        onSuccess: data => {
            notify(`Lightroom preset updated Id=${record.lightroomPresetId} Name=${currentLightroomPresetName}, Status=${getLightroomPresetStatus(currentLightroomPresetStatus)}`, { type: 'success' });
        },
        onError: () => {
            notify("Lightroom preset update failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('LightroomPresets');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSave = async () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await updateLightroomPreset();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingUpdateLightroomPreset) {
        return <CircularProgress />;
    }

    return (
        <>
            <Tooltip title={`Edit lightroom preset ${record.name}`}>
                <IconButton onClick={handleOpen}>
                    <EditIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={record.lightroomPresetId.toString()}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 500,
                    height: 330,
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                }}>
                    <Typography sx={{p: 1}}>{`Edit lightroom preset ${record.name}`}</Typography>
                    <SimpleForm toolbar={false} onSubmit={() => setDialogIsOpen(false)}>
                        <TextInput
                            source="name"
                            label="Name"
                            variant="outlined"
                            onChange={(e) => setCurrentLightroomPresetName(e.target.value)}
                            fullWidth
                        />
                        <LightroomPresetStatusSelectInput
                            source="status"
                            fullWidth
                            onChange={(e) => setcurrentLightroomPresetStatus(e)}
                        />
                        <Button
                            variant="outlined"
                            startIcon={<CheckIcon/>}
                            onClick={onSave}>
                            Save
                        </Button>
                    </SimpleForm>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Edit Lightroom preset?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Update lightroom preset ${record.name}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

