import React, {useCallback, useState} from "react";

import Popover from "@mui/material/Popover";
import {Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, TextField, Tooltip} from "@mui/material";

import FolderZipIcon from '@mui/icons-material/FolderZip';

import {useGetList, useNotify} from "react-admin";
import {
    UserOptionType,
    UserPicker,
    ZipArchiveKindType,
    ZipArchiveKindPicker,
    ZipArchiveMarksType,
    ZipArchiveMarksPicker,
} from "../../Inputs";
import {useMutation} from "react-query";
import {useDataProvider} from "ra-core";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { EventType } from "./types";

export type ZipArchiveActionProps = {
    record: EventType;
}

export type ZipArchiveResponse = {
    isSync: boolean;
    url: string;
}

export type ImageMark = {
    mark: number;
    isMarked: boolean;
}

export const ZipArchiveAction = ({record}: ZipArchiveActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedZipArchiveKind, setSelectedZipArchiveKind] = useState<ZipArchiveKindType | null>(null);
    const [selectedZipArchiveMarks, setSelectedZipArchiveMarks] = useState<ZipArchiveMarksType | null>(null);
    const [selectedZipArchiveSynced, setSelectedZipArchiveSynced] = useState<boolean>(false);
    const [selectedEmail, setSelectedEmail] = useState<string>("");
    const [selectedUser, setSelectedUser] = useState<UserOptionType | null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const dataProvider = useDataProvider();

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload;
    }, []);

    const {data} = useGetList(
        'Image/Marks',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction
            },
        },
        {
            refetchOnMount: false,
        }
    );

    const downloadZipFile = (url: string) => {
        var link = document.createElement("a");
        link.download = "event-images.zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const {mutateAsync: createZipArchive, isLoading: isLoadingCreateZipArchive} = useMutation(() => {
        if (!selectedZipArchiveKind || !selectedZipArchiveMarks || ! selectedUser) {
            return;
        }
        let address = `Event/${record.eventId}/ZipArchive/${selectedUser.value}`;

        if (data)
        {
            let marks: ImageMark[] = [];
            if (selectedZipArchiveMarks.label === "Selected")
            {
                marks = data.map(mark => { return { mark: mark.id, isMarked: false } } );
            }
            else if (selectedZipArchiveMarks.label === "All Images")
            {
                // marks ok
            }

            return dataProvider.saPost(address, {
                kind: selectedZipArchiveKind.value,
                marks: marks,
                synced: selectedZipArchiveSynced,
                email: selectedEmail
            });
        }
    }, {
        onSuccess: (data: { data: { code: number, message: string, payload: ZipArchiveResponse} }) => {
            if (data.data.code > 0) {
                notify(`Creation of zip archive failed - ${data.data.message}`, {type: 'error'});
            } else {
                if (data.data.payload.isSync)
                {
                    notify("Zip archive was created", {type: 'success'});
                    downloadZipFile(data.data.payload.url);
                }
                else
                {
                    notify("Zip archive sent in email", {type: 'success'});
                }

            }
        },
        onError: () => {
            notify("Creation of zip archive failed", {type: 'error'});
        },
        onSettled: () => {
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectZipArchiveKindType = (archiveKind: ZipArchiveKindType) => {
        setSelectedZipArchiveKind(archiveKind);
    }

    const onSelectUser = (user: UserOptionType) => {
        setSelectedUser(user);
    }

    const onSelectZipArchiveMarksType = (archiveMarks: ZipArchiveMarksType) => {
        setSelectedZipArchiveMarks(archiveMarks);
    }

    const onCreateZipArchive = () => {
        setDialogIsOpen(true);
    }

    const createZipArchiveOp = async () => {
        await createZipArchive();
    }

    const handleDialogOk = async () => {
        if (!selectedZipArchiveKind || !selectedZipArchiveMarks || !selectedUser) {
            notify("Please select a user and archive kind and marks", {type: 'error'});
            return;
        }
        setDialogIsOpen(false);
        await createZipArchiveOp();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingCreateZipArchive) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`Create Zip Archive`}>
                <IconButton onClick={handleOpen}>
                    <FolderZipIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={String(record.eventId)}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 400,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="h6">Create Zip Archive</Typography>
                    <UserPicker value={null} onSelect={onSelectUser}/>
                    <ZipArchiveKindPicker
                        value={null}
                        onSelect={onSelectZipArchiveKindType}
                    />
                    <ZipArchiveMarksPicker
                        value={null}
                        onSelect={onSelectZipArchiveMarksType}
                    />
                    <Box sx={{width: 300, height: 50, display: 'flex'}}>
                        <FormControlLabel label="Direct download" sx={{padding:1}} control={
                            <Checkbox
                                checked={selectedZipArchiveSynced}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedZipArchiveSynced(event.target.checked)}
                            />
                        }></FormControlLabel>
                    </Box>
                    <Box sx={{width: 300, height: 50, display: 'flex'}}>
                        <TextField
                            label="Email"
                            name="email"
                            onChange={(e) => setSelectedEmail(e.target.value)}
                            value={selectedEmail}
                            fullWidth
                        />
                    </Box>
                    <Button
                        disabled={!selectedZipArchiveKind || !selectedZipArchiveMarks || !selectedUser}
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onCreateZipArchive}>
                        Create
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Create Zip Archive?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to create a zip archive?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

