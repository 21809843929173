import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ApiIcon from "@mui/icons-material/Api";
import WorkIcon from '@mui/icons-material/Work';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import CelebrationIcon from "@mui/icons-material/Celebration";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import FilterIcon from "@mui/icons-material/Filter";
import ImageIcon from "@mui/icons-material/Image";
import LightIcon from "@mui/icons-material/Light";
import PersonIcon from "@mui/icons-material/Person";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import SettingsIcon from "@mui/icons-material/Settings";
import SmsIcon from "@mui/icons-material/Sms";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import WrapTextIcon from "@mui/icons-material/WrapText";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback } from "react";
import { Admin, CustomRoutes, Layout, Resource, fetchUtils } from "react-admin";
import { Route } from "react-router-dom";
import {
  AccountingTracksList,
  AppBar,
  B2BUserList,
  BackOfficeEquipmentList,
  BackOfficeEquipmentPopover,
  EventCreate,
  EventList,
  EventProcessingList,
  EventTypeCreate,
  EventTypeList,
  EventUpdate,
  ImageStylesList,
  ImagesList,
  LightroomPresetList,
  OTPTestItemsList,
  StripePriceCreate,
  StripePricesList,
  SystemParameterCreate,
  SystemParametersList,
  UserCreate,
  UserList,
  UserUpdate,
  B2BRequestList,
  B2BRequestLogList,
  B2BRequestCreate
} from "./components";
import { B2BUserCreate, B2BUserUpdate } from "./components/Lists/B2BUser";
import { LightroomPresetCreate } from "./components/Lists/LightroomPreset/LightroomPresetCreate";
import { ImageConfigProvider } from "./context/ImageConfigContext";
import { useAppPicker } from "./hooks";
import { LoginPage } from "./pages";
import {
  TokenProvider,
  backendProvider,
  config,
  msalInstance,
  useToken,
} from "./utils";

export const AdminAppLayout = (props: any) => (
  <Layout {...props} appBar={AppBar} />
);

const BackofficeManagerApp = ({ dataProvider }: any) => (
  <Admin disableTelemetry dataProvider={dataProvider} layout={AdminAppLayout}>
    <Resource
      name="User"
      list={UserList}
      create={UserCreate}
      edit={UserUpdate}
      icon={PersonIcon}
      options={{ label: "Users" }}
    />
    <Resource
      name="B2BUser"
      list={B2BUserList}
      create={B2BUserCreate}
      edit={B2BUserUpdate}
      icon={ApiIcon}
      options={{ label: "API users" }}
    />
    <Resource
        name="B2BRequest"
        list={B2BRequestList}
        create={B2BRequestCreate}
        icon={WorkIcon}
        options={{label: "API requests"}}
    />
    <Resource
        name="B2BRequestLog"
        list={B2BRequestLogList}
        icon={FolderCopyIcon}
        options={{label: "API request logs"}}
    />
    <Resource
      name="Equipment"
      list={BackOfficeEquipmentList}
      icon={VideoSettingsIcon}
      options={{ label: "Equipments" }}
    />
    <Resource
      name="Event"
      list={EventList}
      create={EventCreate}
      edit={EventUpdate}
      icon={CelebrationIcon}
      options={{ label: "Events" }}
    />
    <Resource
      name="EventType"
      list={EventTypeList}
      create={EventTypeCreate}
      icon={WrapTextIcon}
      options={{ label: "Event types" }}
    />
    <Resource
      name="SystemParameters"
      list={SystemParametersList}
      create={SystemParameterCreate}
      icon={SettingsIcon}
      options={{ label: "System parameters" }}
    />
    <Resource
      name="StripePrices"
      list={StripePricesList}
      create={StripePriceCreate}
      icon={PriceChangeIcon}
      options={{ label: "Stripe prices" }}
    />
    <Resource
      name="ImageStyles"
      list={ImageStylesList}
      icon={FilterIcon}
      options={{ label: "Image styles" }}
    />
    <Resource
      name="Images"
      list={ImagesList}
      icon={ImageIcon}
      options={{ label: "Images" }}
    />
    <Resource
      name="EventProcessing"
      list={EventProcessingList}
      icon={CloudSyncIcon}
      options={{ label: "Event processing" }}
    />
    <Resource
      name="LightroomPreset"
      list={LightroomPresetList}
      icon={LightIcon}
      options={{ label: "Lightroom presets" }}
      create={LightroomPresetCreate}
    />
    <Resource
      name="AccountingTracks"
      list={AccountingTracksList}
      icon={ImageIcon}
      options={{ label: "Accounting" }}
    />
    <Resource
      name="OTPTestItems"
      list={OTPTestItemsList}
      icon={SmsIcon}
      options={{ label: "OTP test numbers" }}
    />
    <CustomRoutes noLayout={true}>
      <Route
        path="/Equipment/Popover/:serial"
        element={<BackOfficeEquipmentPopover />}
      />
    </CustomRoutes>
  </Admin>
);

const AdminApp = () => {
  const { currentApp } = useAppPicker();
  const { accessToken } = useToken();

  const httpClient = useCallback(
    (url: string, options: fetchUtils.Options = {}) => {
      options.user = {
        authenticated: accessToken !== null,
        token: `Bearer ${accessToken}`,
      };
      return fetchUtils.fetchJson(url, options);
    },
    [accessToken]
  );

  const dataProvider = backendProvider(config.API_URL!, httpClient);

  if (!accessToken || !currentApp) {
    return <CircularProgress />;
  }

  switch (currentApp.type) {
    case "BackofficeManager":
      return <BackofficeManagerApp dataProvider={dataProvider} />;
    default:
      return <CircularProgress />;
  }
};

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <TokenProvider>
          <ImageConfigProvider>
            <AdminApp />
          </ImageConfigProvider>
        </TokenProvider>
      </AuthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
