import React from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {FunctionField} from "react-admin";

type UserPopoverFieldProps = {
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>, currentEquipmentId: number) => void;
    handleClose: () => void;
    anchorEl: HTMLButtonElement | null;
    currentEquipmentId: number | null;
}

export const UserPopoverField = (props: UserPopoverFieldProps) => {
    const {
        handleOpen,
        handleClose,
        anchorEl,
        currentEquipmentId
    } = props;

    return (
        <FunctionField label="user" render={(record: any) => {
            if (!record.user?.firstName && !record.user?.lastName) {
                return (<></>);
            }
            const user = `${record.user?.firstName ?? ''} ${record.user?.lastName ?? ''}`;

            return (
                <>
                    <Button onClick={(e) => handleOpen(e, record.equipmentId)}>{user}</Button>
                    <Popover
                        id={record.user?.userId}
                        open={currentEquipmentId === record.equipmentId}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 1}}><b>user id:</b> {record.user?.userId}</Typography>
                        <Typography sx={{p: 1}}><b>first name:</b> {record.user?.firstName}</Typography>
                        <Typography sx={{p: 1}}><b>last name:</b> {record.user?.lastName}</Typography>
                        <Typography sx={{p: 1}}><b>email address:</b> {record.user?.email}</Typography>
                        <Typography sx={{p: 1}}><b>phone number:</b> {record.user?.phoneNumber}</Typography>
                    </Popover>
                </>
            );
        }}/>
    );
}
