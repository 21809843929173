import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import {
    BooleanField,
    Datagrid,
    DateTimeInput,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    TextField,
    TextInput,
    WrapperField,
} from "react-admin";
import { TimestampDateField } from "../../Fields";
import { UserSelectInput } from "../../Inputs";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { UserPopoverField } from "../../Popover";
import { ActionsField } from "./ActionsField";
import { EventUserList } from "./EventUsersList";
import { ZipArchiveAction } from "./ZipArchive";
import { EventType } from "./types";
import { useProcessingStatuss } from "./useProcessingStatus";

const eventFilters = [
    <DateTimeInput
        label={"From Date"}
        source={"StartDate"}
        parse={(date) => String(new Date(date).getTime() / 1000)}
    />,
    <DateTimeInput
        label={"To Date"}
        source={"EndDate"}
        parse={(date) => String(new Date(date).getTime() / 1000)}
    />,
    <UserSelectInput
        label={"Creator User"}
        source={"CreatorUserId"}
    />,
    <UserSelectInput
        label={"Participant User"}
        source={"ParticipantUserId"}
    />,
    <NumberInput
        label={"Event Id"}
        source={"eventId"}
        alwaysOn
    />,
    <NumberInput
        label={"Processing Status"}
        source={"processingStatus"}
    />,
    <TextInput
        label={"Name"}
        source={"name"}
        alwaysOn
    />,
];

export const EventList = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentEventId, setCurrentEventId] = useState<number | null>(null);
    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);
    const { getProcessingStatus } = useProcessingStatuss();

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, currentEventId: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentEventId(currentEventId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentEventId(null);
    };

    const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>, currentEventId: number) => {
        SetModalIsOpen(true);
        setCurrentEventId(currentEventId);
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
        setCurrentEventId(null);
    }

    return (
        <>
            <List
                title="Events"
                queryOptions={{
                    meta: {
                        overridePath: 'Event/Search',
                        alternativeId: 'eventId',
                        pagination: true,
                        dataExtractFunction: (data: { payload: any }) => {
                            return data.payload.results;
                        }
                    },
                    refetchOnMount: false,
                }}
                perPage={25}
                filters={eventFilters}
                sort={{ field: "startDate", order: "DESC" }}
            >
                <ListFilterCleaner>
                    <Datagrid
                        bulkActionButtons={false}
                    >
                        <TextField sortable={false} source="eventId"/>
                        <TextField sortable={false}source="name"/>
                        <FunctionField sortable={false} label={"Event type"} source="eventType"
                            render={(record: EventType) => {
                                return record.eventType["name"];
                            }}/>
                        <TimestampDateField sortable={false} source="startDate"/>
                        <TimestampDateField sortable={false} source="endDate"/>
                        <NumberField sortable={false} label="Uploaded" source="uploadedImageCount"/>
                        <NumberField sortable={false} label="Edited" source="editedImageCount"/>
                        <TimestampDateField sortable={false} source="lastUploadTimestamp"/>
                        <WrapperField sortable={false} source={'creator'}>
                            <UserPopoverField
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                                anchorEl={anchorEl}
                                currentResourceId={currentEventId}
                                resourceIdKey={'eventId'}
                                resource={'creator'}
                            />
                        </WrapperField>
                        <FunctionField sortable={false} label={"Processing Status"} source="processingStatus"
                            render={(record: EventType) => {
                                return getProcessingStatus(record.processingStatus);
                            }}/>

                        <BooleanField sortable={false} label="Face rec ?" source="faceRecEnabled"></BooleanField>
                        <BooleanField sortable={false} label="Demo ?" source="isDemo"></BooleanField>
                        <BooleanField sortable={false} label="Demo src ?" source="isDemoSource"></BooleanField>
                        <FunctionField sortable={false} label={"Event Users"} render={(record: EventType) => (
                            <Button
                                onClick={(e) => handleOpenModal(e, record.eventId)}
                            >
                                Users
                            </Button>
                        )}/>
                        <FunctionField sortable={false}
                            label={"Zip Archive"}
                            render={(record: EventType) => (
                                <ZipArchiveAction record={record}/>
                            )}
                        />
                        <ActionsField/>
                    </Datagrid>
                </ListFilterCleaner>
            </List>
            <Modal
                open={modalIsOpen}
                onClose={handleCloseModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    width: '90vw',
                    height: '90vh',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    p: 4,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'auto'
                }}>
                    <Typography variant="h5" component="div">
                        Event Users
                    </Typography>
                    <EventUserList eventId={currentEventId!}/>
                </Box>
            </Modal>
        </>
    );
}
