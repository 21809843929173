import React from 'react';
import {
    useParams
} from "react-router-dom";
import {useQuery} from "react-query";
import {useDataProvider} from "ra-core";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Divider} from "@mui/material";

export const BackOfficeEquipmentPopover = () => {
    const params = useParams();
    const serial = params.serial;

    const dataProvider = useDataProvider();

    const { data, isLoading, error } = useQuery(`Equipment-${serial}`, () => {
        if (!serial) {
            return;
        }
        return dataProvider.saGet(`Equipment?Serial=${serial}&Offset=0&Limit=1&OnlyUnassigned=false`, {});
    }, );

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography sx={{p: 1}}><b>Error Fetching Equipment Data</b></Typography>;
    }

    if (!data?.data?.payload || !data?.data?.payload?.results || data?.data?.payload?.results?.length === 0) {
        return <Typography sx={{p: 1}}><b>Equipment not found</b></Typography>;
    }

    const eq = data.data.payload.results[0];

    return (
        <Box>
            <Typography sx={{p: 1}}><b>Equipment ID:</b> {eq.equipmentId}</Typography>
            <Typography sx={{p: 1}}><b>Equipment Serial:</b> {eq.serial}</Typography>
            <Typography sx={{p: 1}}><b>Created:</b> {new Date(eq.created * 1000).toLocaleString()}</Typography>
            <Divider sx={{my: 1}}/>
            <Typography variant={'h5'} sx={{p: 1}}>Attached User:</Typography>
            <Typography sx={{p: 1}}><b>User Id:</b> {eq.user?.userId}</Typography>
            <Typography sx={{p: 1}}><b>First Name:</b> {eq.user?.firstName}</Typography>
            <Typography sx={{p: 1}}><b>Last Name:</b> {eq.user?.lastName}</Typography>
            <Typography sx={{p: 1}}><b>Email Address:</b> {eq.user?.email}</Typography>
            <Typography sx={{p: 1}}><b>Phone Number:</b> {eq.user?.phoneNumber}</Typography>
            <Divider sx={{my: 1}}/>
        </Box>
    );
}
