import React, {useCallback, useState} from 'react';
import {useGetList} from "react-admin";

import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {getFullName} from "../../utils";
import {UserPickerType} from "../../types";

export type UserOptionType = UserPickerType | null;

type UserPickerProps = {
    value: UserOptionType;
    onSelect: (value: UserPickerType) => void;
}

export const UserPicker = (props: UserPickerProps) => {
    const {value: val, onSelect} = props;

    const [value, setValue] = useState<UserOptionType>(val);

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload.results;
    }, []);

    const {data, isLoading, error} = useGetList(
        'user',
        {
            filter: {'Limit': -1, 'Offset': 0, 'IncludeDeleted': false},
            meta: {
                pagination: false,
                alternativeId: 'userId',
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    if (error) {
        return <p>ERROR</p>;
    }

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{width: 300, height: 50, display: 'flex'}}
            >
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Box sx={{width: 300, height: 50, display: 'flex'}}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as UserPickerType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="user-picker"
                options={data?.map(op => ({
                    label: `${getFullName(op)} (${op.userId})`,
                    value: op.userId
                })) ?? [] as UserOptionType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props}>{option?.label}</li>}
                sx={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label="Search User"/>
                )}
            />
        </Box>
    );
}
