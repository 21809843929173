import { useDataProvider } from "ra-core";
import { useState } from "react";
import {
  Create,
  FileInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from "react-admin";
import { useQueryClient } from "react-query";
import { UserSelectInput } from "../../Inputs";
import { LightroomPreset } from "./types";

export const LightroomPresetCreate = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);

  const createLightroomPresets = async (
    name: string,
    userId: number,
    files: any[],
    baseHALDName: string
  ) => {
    let url = "LightroomPresets?";
    if (userId) {
      url += `&UserId=${userId}`;
    }

    // single file as before
    if (files.length === 1) {
      const rawFile: File = files[0].rawFile;

      // single file can specify name
      if (name) {
        url += `&Name=${name}`;
      }
      const response: {
        code: number;
        message: string;
        payload: LightroomPreset;
      } = await dataProvider.saPutFile(url, {}, rawFile);
      if (response.code === 0) {
        notify(`Lightroom Preset ${rawFile.name} created successfully`, {
          type: "success",
        });
      } else {
        notify(`Lightroom Preset ${rawFile.name} failed to create`, {
          type: "error",
        });
      }
      return;
    }

    // multiple files upload

    const rawFiles: File[] = files.map((file) => file.rawFile);

    const xmpFiles = rawFiles.filter((rawFile) =>
      rawFile.name.toLowerCase().endsWith(".xmp")
    );
    const haldFiles = rawFiles.filter(
      (rawFile) =>
        rawFile.name.toLowerCase().endsWith(".png") ||
        rawFile.name.toLowerCase().endsWith(".tif")
    );

    for (const xmpFile of xmpFiles) {
      const xmpFileName = xmpFile.name.toLowerCase();

      const presetName = xmpFileName.substring(0, xmpFileName.length - 4);

      const response: {
        data: { code: number; message: string; payload: LightroomPreset };
      } = await dataProvider.saPutFile(url, {}, xmpFile);

      if (response.data.code === 0) {
        notify(`Lightroom Preset ${presetName} created successfully`, {
          type: "success",
        });
      } else {
        notify(`Lightroom Preset ${presetName} failed to create`, {
          type: "error",
        });
      }

      const lightroomPreset: LightroomPreset = response.data.payload;

      const presetHaldFiles = haldFiles.filter((haldFile) =>
        haldFile.name.toLowerCase().startsWith(presetName)
      );
      if (presetHaldFiles.length > 0) {
        const presetHaldFile = presetHaldFiles[0];

        let activateUrl = `LightroomPresets/${lightroomPreset.lightroomPresetId}/Activate?BaseHALDName=${baseHALDName}`;
        const response: {
          data: { code: number; message: string; payload: LightroomPreset };
        } = await dataProvider.saPostFile(activateUrl, {}, presetHaldFile);

        if (response.data.code === 0) {
          notify(`Lightroom Preset ${presetName} activated successfully`, {
            type: "success",
          });
        } else {
          notify(`Lightroom Preset ${presetName} failed to activated`, {
            type: "error",
          });
        }
      }
    }
  };

  return (
    <Create>
      <SimpleForm
        onSubmit={async (formValues) => {
          if (!formValues.files) {
            notify("No file selected", { type: "error" });
            return;
          }
          await createLightroomPresets(
            formValues.name,
            formValues.userId,
            formValues.files,
            formValues.baseHALDName
          );
          queryClient.invalidateQueries("LightroomPresets");
          redirect("list", "LightroomPreset");
        }}
      >
        <TextInput
          label="Preset name (only for single file. leave blank to use name)"
          source="name"
          fullWidth
        />
        <UserSelectInput
          label="User (leave blank for global preset)"
          source="userId"
          fullWidth
        />
        <SelectInput
          validate={required()}
          label={"HALD name (required for multiple)"}
          source="baseHALDName"
          choices={[
            { id: "hue", name: "hue" },
            { id: "hald_4096", name: "hald_4096" },
            { id: "base_hald_0", name: "base_hald_0" },
          ]}
          defaultValue={"hue"}
          fullWidth
        />
        <FileInput
          accept={".xmp,.png,.tif"}
          source="files"
          label="Lightroom preset XMP files, HALD png/tif files"
          multiple={true}
          isRequired={true}
          onChange={(files: File[]) => {
            setSelectedFileNames(files.map((file) => file.name));
          }}
        ></FileInput>
        <p>Selected files: {selectedFileNames.join(", ")}</p>
      </SimpleForm>
    </Create>
  );
};
