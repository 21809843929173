import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

type CountryType = {
    id: number,
    code: string,
    name: string
};

export const useCountryTypes = () => {

    const [countryTypes, setCountryTypes] = useState<CountryType[]>([]);

    const getCountryName = (id: number) => {
        return countryTypes.find(u => u.id === id)?.name;
    }

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload.results;
    }, []);

    const {data, isLoading} = useGetList(
        'Countries',
        {
            filter: {'Limit': 1000, 'Offset': 0},
            meta: {
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setCountryTypes(data);
        }
    }, [data, isLoading]);

    return {
        countryTypes: countryTypes,
        getCountryName: getCountryName,
        isLoading: isLoading
    }
}