import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

type UserType = {
    id: number,
    name: string
};

export const useUserTypes = () => {

    const [userTypes, setUserTypes] = useState<UserType[]>([]);

    const getUserType = (userType: number) => {
        return userTypes.find(u => u.id === userType)?.name;
    }

    const getUserTypeNames = () => {
        return userTypes.map(u => u.name);
    }

    const getUserTypeId = (name: string) => {
        return userTypes.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'User/UserType',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setUserTypes(data);
        }
    }, [data, isLoading]);

    return {
        userTypes: userTypes,
        getUserType: getUserType,
        getUserTypeNames: getUserTypeNames,
        isLoading: isLoading,
        getUserTypeId: getUserTypeId,
    }
}