import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

export type ImageProcessingStage = {
    id: number,
    name: string
};

export const useImageProcessingStages = () => {

    const [imageProcessingStages, setImageProcessingStages] = useState<ImageProcessingStage[]>([]);

    const getImageProcessingStage = (imageProcessingStage: number) => {
        return imageProcessingStages.find(u => u.id === imageProcessingStage)?.name;
    }

    const getImageProcessingStageNames = () => {
        return imageProcessingStages.map(u => u.name);
    }

    const getImageProcessingStageId = (name: string) => {
        return imageProcessingStages.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: ImageProcessingStage[] }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'Image/ImageProcessingStage/Stages',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setImageProcessingStages(data);
        }
    }, [data, isLoading]);

    return {
        imageProcessingStages: imageProcessingStages,
        getImageProcessingStage: getImageProcessingStage,
        getImageProcessingStageNames: getImageProcessingStageNames,
        isLoading: isLoading,
        getImageProcessingStageId: getImageProcessingStageId,
    }
}