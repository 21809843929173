import {useCallback, useEffect, useState} from 'react';
import {useGetList, AutocompleteInput, AutocompleteInputProps} from "react-admin";
import { ImageStyle } from '../Lists/ImageStyle/types';

export const ImageStyleSelectInput = (props: AutocompleteInputProps) => {
    const {label, ...rest} = props;

    const [data, setData] = useState<ImageStyle[] | undefined>([]);

    const dataExtractFunction = useCallback((data: { payload: { results: ImageStyle[] } }) => {
        return data.payload.results;
    }, []);

    const {data: originalData, isLoading, error} = useGetList(
        'ImageStyles',
        {
            filter: {'Limit': -1, 'Offset': 0, 'OnlyAvailable': true},
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (!data?.length) {
            setData(originalData);
        }
    }, [originalData, data?.length]);


    if (error) {
        return null;
    }

    return (
        <AutocompleteInput
            label={label}
            choices={data?.map((imageStyle: any) => ({id: imageStyle.id, name: imageStyle.name + " ("+ imageStyle.id.toString() + ")"}))}
            isLoading={isLoading}
            isFetching={isLoading}
            sx={{width: '100%'}}
            {...rest}
        />
    );
}
