import { useState } from "react";

import CachedIcon from '@mui/icons-material/Cached';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useDataProvider } from "ra-core";
import { useNotify } from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { B2BUser } from "./types";

export type Sync2CacheB2BUserProps = {
  record: B2BUser;
};

export const Sync2CacheB2BUser = ({
  record,
}: Sync2CacheB2BUserProps) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const {
    mutateAsync: sync2CacheB2BUser,
    isLoading: isLoadingSync2CacheB2BUser,
  } = useMutation(
    () => {
      const b2BUserId = record.b2BUserId;
      if (!b2BUserId) {
        return;
      }
      return dataProvider.saPost(`B2BUsers/${b2BUserId}/Sync2Cache`, {});
    },
    {
      onSuccess: (data) => {
        notify("API user synced to cache successfully", { type: "success" });
      },
      onError: () => {
        notify("API user synced to cache failed", { type: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries("B2BUser");
      },
    }
  );

  const handleClick = () => {
    setDialogIsOpen(true);
    return;
  };

  const handleDialogOk = async () => {
    setDialogIsOpen(false);
    await sync2CacheB2BUser();
    return;
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
    return;
  };

  return (
    <>
      <Tooltip title="Sync to cache API User">
        {isLoadingSync2CacheB2BUser ? (
          <CircularProgress />
        ) : (
          <IconButton onClick={handleClick}>
            <CachedIcon />
          </IconButton>
        )}
      </Tooltip>
      <Dialog
        open={dialogIsOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sync to cache API User?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to sync to cache this API user ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogOk} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
