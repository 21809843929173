import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import { LightroomPreset } from "./types";
import SimpleBackdrop from "../../SimpleBackdrop";
import {
  FileInput,
  SelectInput,
  SimpleForm,
  required,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";

export type ActivateLightroomPresetActionProps = {
  record: LightroomPreset;
};

export const ActivateLightroomPresetAction = ({
  record,
}: ActivateLightroomPresetActionProps) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const {
    mutateAsync: activateLightroomPreset,
    isLoading: isLoadingAddLightroomPreset,
  } = useMutation(
    ({ baseHALDName, file }: { baseHALDName: string; file: File }) => {
      let url = `LightroomPresets/${record.lightroomPresetId}/Activate?BaseHALDName=${baseHALDName}`;
      return dataProvider.saPostFile(url, {}, file);
    },
    {
      onSuccess: (data) => {
        notify("Lightroom preset activated successfully", { type: "success" });
        redirect("list", "LightroomPreset");
      },
      onError: (data) => {
        notify("Lightroom preset activation failed", { type: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries("LightroomPresets");
      },
    }
  );

  const handleActivate = () => {
    setDialogIsOpen(true);
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  return (
    <>
      <Tooltip title={`Activate lightroom preset ${record.name}`}>
        <IconButton onClick={handleActivate}>
          <PowerSettingsNewIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogIsOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Activate lightroom preset ${record.name}?`}
        </DialogTitle>
        <DialogContent>
          <SimpleBackdrop open={isLoadingAddLightroomPreset} />
          <SimpleForm
            onSubmit={async (formValues) => {
              if (!formValues.file) {
                notify("No file selected", { type: "error" });
                return;
              }
              await activateLightroomPreset({
                baseHALDName: formValues.baseHALDName,
                file: formValues.file.rawFile,
              });
              handleDialogClose();
            }}
          >
            <SelectInput
              validate={required()}
              label={"HALD name (required)"}
              source="baseHALDName"
              choices={[
                { id: "hue", name: "hue" },
                { id: "hald_4096", name: "hald_4096" },
                { id: "base_hald_0", name: "base_hald_0" },
              ]}
              defaultValue={"hue"}
              fullWidth
            />
            <FileInput
              type="file"
              accept=".tif,.png"
              source="file"
              label="Lightroom preset HALD file (TIF/PNG)"
              multiple={false}
              isRequired={true}
              fullWidth
            ></FileInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
