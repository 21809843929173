import {Datagrid, FunctionField, List, TextField, TextInput, WrapperField} from "react-admin";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import { EditSystemParameterAction } from "./EditSystemParameterAction";
import { SystemParametersActionsField } from "./SystemParametersActionsField";

const systemParameterFilters = [
    <TextInput
        label="Parameter name"
        source="paramName"
        resettable
    />,
];

export const SystemParametersList = () => (
    <List
        title="System parameters"
        queryOptions={{
            meta: {
                alternativeId: 'paramName',
                pagination: true,
                dataExtractFunction: (data: { payload: any }) => {
                    return data.payload.results;
                },
                overridePath: 'SystemParameters'
            },
            refetchOnMount: true,
        }}
        perPage={25}
        filters={systemParameterFilters}
    >
        <ListFilterCleaner>
            <Datagrid bulkActionButtons={false}>
                <TextField sortable={false} label="Parameter name" source="paramName"/>
                <WrapperField source={'paramValue'}>
                    <TextField source="paramValue"/>
                    <FunctionField label={"Parameter value"} render={(record: any) => (
                        <EditSystemParameterAction record={record}/>
                    )}/>
                </WrapperField>
                <SystemParametersActionsField/>
            </Datagrid>
        </ListFilterCleaner>
    </List>
);
