import {FunctionField} from "react-admin";
import {DeleteEvent} from "./DeleteEvent";
import {RedoCullingAction} from "./RedoCullingAction";
import {RedoGroupingAction} from "./RedoGroupingAction";
import {ResendNotEditedAction} from "./ResendNotEditedAction";
import {RestoreEvent} from "./RestoreEvent";
import {EditEvent} from "./EditEventAction";
import Box from "@mui/material/Box";
import {HeartbeatPopoverField} from "./HeartbeatPopoverField";
import { UpdateStyleAction } from "./UpdateStyleAction";
import { EventType } from "./types";


export const ActionsField = () => {
    return (
        <FunctionField
            label={"Actions"}
            render={
                (record: EventType) => {
                    return (
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <HeartbeatPopoverField record={record}/>
                            {
                                record.isDelete ? <RestoreEvent record={record}/> : <>
                                    <EditEvent record={record}/>
                                    <DeleteEvent record={record}/>
                                </>
                            }
                            <RedoCullingAction record={record}/>
                            <RedoGroupingAction record={record}/>
                            <ResendNotEditedAction record={record}/>
                            <UpdateStyleAction record={record}/>
                        </Box>
                    );
                }
            }
        />
    );
};
