import Typography from "@mui/material/Typography";
import { useDataProvider } from "ra-core";
import { Create, useNotify, useRedirect } from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import SimpleBackdrop from "../../SimpleBackdrop";
import { UpsertB2BRequestForm } from "./UpsertB2BRequestForm";
import { B2BRequestCreateInput } from "./types";

export const B2BRequestCreate = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const { mutateAsync: addB2BRequest, isLoading: isLoadingAddRequest } = useMutation(
    (input: B2BRequestCreateInput) => {
      return dataProvider.saPut(`B2BRequests?` +
        `&B2BUserId=${input.b2BUserId}` +
        `&SourcePath=${input.sourcePath}` +
        `&Params=${input.params}`);
    },
    {
      onSuccess: async (data: { data: { code: number; message: string } }) => {
        if (data.data.code > 0) {
          notify(`API Request creation failed - ${data.data.message}`, {
            type: "error",
          });
        } else {
          notify("API Request created successfully", { type: "success" });
          redirect("list", "B2BRequest");
        }
      },
      onError: (data) => {
        notify("Request creation failed", { type: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries(`Request`);
      },
    }
  );

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ marginTop: 5 }}>
        Add New API Request
      </Typography>
      <SimpleBackdrop open={isLoadingAddRequest} />
      <Create>
        <UpsertB2BRequestForm
          onSubmit={(e) => addB2BRequest(e as B2BRequestCreateInput)}
          isUpdate={false}
        />
      </Create>
    </>
  );
};
