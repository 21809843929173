import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import { useRedirect } from "react-admin";
import { B2BUser } from "./types";

export type EditB2BUserProps = {
  record: B2BUser;
};

export const EditB2BUser = ({ record }: EditB2BUserProps) => {
  const redirect = useRedirect();

  const handleClick = () => {
    redirect("edit", "B2BUser", record.b2BUserId);
  };

  return (
    <>
      <Tooltip title="Edit API User">
        {
          <IconButton onClick={handleClick}>
            <EditIcon />
          </IconButton>
        }
      </Tooltip>
    </>
  );
};
