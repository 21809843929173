import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { useRecordContext } from "ra-core";
import { useState } from "react";
import { useImageConfig } from "../../context/ImageConfigContext";
import { FaceRecTag } from "../Lists/Image/types";
import { FaceRecCanvas } from "./FaceRecCanvas";

interface FaceRecImageFieldProps {
  imageType: string;
  label?: string;
  faceRecTags: FaceRecTag[];
}

export const FaceRecImageField = (props: FaceRecImageFieldProps) => {
  const record = useRecordContext(props);
  const { imageType, faceRecTags } = props;

  const { getImageUrl } = useImageConfig();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = async () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const thumbnailUrl = getImageUrl(
    record.fileName,
    record.thumbnailFileName,
    imageType,
    "Thumbnail",
    record.rawPath
  );


  const imageUrl = getImageUrl(
    record.fileName,
    record.thumbnailFileName,
    "edited",
    "Large"
  );

  const displayName = props.label ?? record.imageName;

  return (
    <>
      <img
        src={thumbnailUrl}
        alt={displayName}
        style={{
          height: "50px",
          cursor: "pointer",
          objectFit: "cover",
          border: "3px solid #ccc",
        }}
        onMouseOver={openModal}
        onMouseOut={closeModal}
      />

      <Modal
        open={!!modalIsOpen}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        style={{
          pointerEvents: "none",
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height="90vh"
          padding="5vh"
        >
          <FaceRecCanvas imageUrl={imageUrl} faceRecTags={faceRecTags}/>
        </Box>
      </Modal>
    </>
  );
};
