import {useEffect, useRef, useState} from "react";
import {config} from "../utils";

type App = {
    type: "BackofficeManager" | "EnvironmentManager";
    stage: "dev" | "qa" | "prod";
    name: string;
    url: string;
}

export const useAppPicker = () => {
    const apps = useRef<App[]>([
        {
            type: 'BackofficeManager',
            stage: 'dev',
            name: "Dev Backoffice",
            url: "https://backdev.snapify.ai",
        },
        {
            type: 'BackofficeManager',
            stage: 'qa',
            name: "Qa Backoffice",
            url: "https://backqa.snapify.ai",
        },
        {
            type: 'BackofficeManager',
            stage: 'prod',
            name: "Prod Backoffice",
            url: "https://back.snapify.ai",
        },
        {
            type: 'EnvironmentManager',
            stage: 'prod',
            name: "Environment Manager",
            url: "https://em.snapify.ai",
        }
    ]);
    const [currentApp, setCurrentApp] = useState<App | undefined>();

    useEffect(() => {
        const cuApp = apps.current.find(app => app.type === config.SELECTED_APP && app.stage === config.SELECTED_STAGE);
        setCurrentApp(cuApp);
    }, []);

    const onAppSelected = (app: string) => {
        window.location.href = app;
    }

    return {
        onAppSelected: onAppSelected,
        currentApp: currentApp,
        apps: apps.current,
    }
}
