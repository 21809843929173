import React, {useState} from "react";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const WifiHiddenPassword = ({ password }: {password: string}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Input
            disabled
            disableUnderline
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                    >
                        {showPassword 
                            ? <VisibilityOffIcon sx={{ fontSize: 14 }} /> 
                            : <VisibilityIcon sx={{ fontSize: 14 }} />
                        }
                    </IconButton>
                </InputAdornment>
            }
            size="small"
            sx={{ width: 100, height: 28, paddingRight: 0 }}
            type={showPassword ? "text" : "password"}
            value={password}
        />
    );
};