import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useDataProvider } from "ra-core";
import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { ImageStyle } from "./types";

export type EditImageStyleActionProps = {
  record: ImageStyle;
};

export const EditImageStyleAction = ({ record }: EditImageStyleActionProps) => {
  const notify = useNotify();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentImageStyleName, setCurrentImageStyleName] = useState<string>(
    record.name
  );
  const [currentImageStyleAvailable, setCurrentImageStyleAvailable] =
    useState<boolean>(record.available);
  const [currentImageStyleComment, setCurrentImageStyleComment] =
    useState<string>(record.comment);
  const [currentImageStyleOrderIndex, setCurrentImageStyleOrderIndex] =
    useState<number>(record.orderIndex);
  const [currentImageStyleCategory, setCurrentImageStyleCategory] =
    useState<string>(record.category);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setCurrentImageStyleName(record.name);
    setCurrentImageStyleAvailable(record.available);
    setCurrentImageStyleComment(record.comment);
    setCurrentImageStyleOrderIndex(record.orderIndex);
    setCurrentImageStyleCategory(record.category);
  }, [record]);

  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();

  const {
    mutateAsync: updateImageStyle,
    isLoading: isLoadingUpdateImageStyle,
  } = useMutation(
    () => {
      const uri: string =
        `ImageStyles/${record.id}?Name=${encodeURIComponent(
          currentImageStyleName
        )}` +
        `&Available=${encodeURIComponent(String(currentImageStyleAvailable))}` +
        `&Comment=${encodeURIComponent(currentImageStyleComment)}` +
        `&OrderIndex=${encodeURIComponent(
          String(currentImageStyleOrderIndex)
        )}` +
        `&Category=${currentImageStyleCategory ? encodeURIComponent(currentImageStyleCategory) : ""}`;
      return dataProvider.saPost(uri, {});
    },
    {
      onSuccess: async (data: {
        data: { code: number; message: string; payload: ImageStyle[] };
      }) => {
        if (data.data.code > 0) {
          notify(`Image style update failed - ${data.data.message}`, {
            type: "error",
          });
        } else {
          notify(
            `Image style updated Name=${record.name} Available=${currentImageStyleAvailable}, Comment=${currentImageStyleComment}`,
            { type: "success" }
          );
        }
      },
      onError: () => {
        notify("Image style update failed", { type: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries("ImageStyles");
      },
    }
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSave = async () => {
    setDialogIsOpen(true);
    return;
  };

  const handleDialogOk = async () => {
    setDialogIsOpen(false);
    await updateImageStyle();
    handleClose();
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
    handleClose();
  };

  if (isLoadingUpdateImageStyle) {
    return <CircularProgress />;
  }

  return (
    <>
      <Tooltip title={`Edit image style ${record.name}`}>
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={record.name}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: 600,
            height: 500,
            padding: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography
            sx={{ p: 1 }}
          >{`Edit image style ${record.name}`}</Typography>
          <SimpleForm toolbar={false} onSubmit={() => setDialogIsOpen(false)}>
            <TextInput
              source="name"
              label="Name"
              variant="outlined"
              onChange={(e) => setCurrentImageStyleName(e.target.value)}
              defaultValue={record.name}
              fullWidth
            />
            <BooleanInput
              source="available"
              label="Available ?"
              variant="outlined"
              onChange={(e) => setCurrentImageStyleAvailable(e.target.checked)}
              defaultValue={record.available}
            />
            <TextInput
              source="comment"
              label="Comment"
              variant="outlined"
              onChange={(e) => setCurrentImageStyleComment(e.target.value)}
              fullWidth
              defaultValue={record.comment}
            />
            <NumberInput
              source="orderIndex"
              label="Order Index"
              variant="outlined"
              onChange={(e) => setCurrentImageStyleOrderIndex(e.target.value)}
              fullWidth
              defaultValue={record.orderIndex}
            />
            <SelectInput
              choices={[
                { id: "", name: "" },
                { id: "Black and White", name: "Black and White" },
                { id: "Boho Vibes", name: "Boho Vibes" },
                { id: "Cinematic", name: "Cinematic" },
                { id: "Creative", name: "Creative" },
                { id: "Light and Airy", name: "Light and Airy" },
                { id: "Spring Vibes", name: "Spring Vibes" },
              ]}
              defaultValue={record.category}
              source="category"
              label="Category"
              onChange={(e) => setCurrentImageStyleCategory(e.target.value)}
              fullWidth
            />
            <Button
              variant="outlined"
              startIcon={<CheckIcon />}
              onClick={onSave}
            >
              Save
            </Button>
          </SimpleForm>
        </Box>
      </Popover>
      <Dialog
        open={dialogIsOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit Image style?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Change image style name = ${currentImageStyleName},
                        available = ${currentImageStyleAvailable},
                        comment = ${currentImageStyleComment},
                        orderIndex = ${currentImageStyleOrderIndex} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogOk} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
