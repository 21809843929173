import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";
import { useState } from "react";

export type DeleteSystemParameterActionProps = {
    record: any;
}

export const DeleteSystemParameterAction = ({record}: DeleteSystemParameterActionProps) => {
    const notify = useNotify();

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const {mutateAsync: removeSystemParameter, isLoading: isLoadingRemoveSystemParameter} = useMutation(() => {
        if (!record.paramName) {
            return;
        }
        return dataProvider.saDelete(`SystemParameters?paramName=${record.paramName}`, {});
    }, {
        onSuccess: (response: { data: { code: number, message: string } }) => {
            if (response.data.code > 0) {
                notify(`System parameter deletion failed - ${response.data.message}`, {type: 'error'});
            } else {
                notify("System parameter deleted successfully", {type: 'success'});
            }
        },
        onError: () => {
            notify("System parameter deletion failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`SystemParameters`);
        }
    });

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await removeSystemParameter();
    }

    if (isLoadingRemoveSystemParameter) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`Delete system parameter`}>
                <IconButton onClick={() => setDialogIsOpen(true)}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
            <Dialog
                open={dialogIsOpen}
                onClose={() => setDialogIsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete system parameter?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Delete system parameter {record.paramName} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogIsOpen(false)}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

