import React from "react";
import {
    Create,
    useNotify,
    useRedirect,
} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";
import {UserCreateInput} from "../../../types";
import {UpsertUserForm} from "./UpsertUserForm";

export const UserCreate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {
        mutateAsync: addUser,
        isLoading: isLoadingAddUser
    } = useMutation((input: UserCreateInput) => {
        return dataProvider.saPost(`User`, input);
    }, {
        onSuccess: async (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`User creation failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("User created successfully", {type: 'success'});
                redirect('list', 'User');
            }
        },
        onError: (data) => {
            notify("User creation failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`User`);
        }
    });

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Add New User
            </Typography>
            <SimpleBackdrop open={isLoadingAddUser}/>
            <Create>
                <UpsertUserForm onSubmit={(e) => addUser(e as UserCreateInput)} isUpdate={false}/>
            </Create>
        </>
    );
};
