import React, {useState} from 'react';

import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {UserTypePickerType} from "../../types";

export type UserTypeOptionType = UserTypePickerType | null;

type UserTypePickerProps = {
    value: UserTypeOptionType;
    onSelect: (value: UserTypeOptionType) => void;
}

const data = [
    {
        label: 'Owner',
        value: 1,
    },
    {
        label: 'Guest',
        value: 2,
    },
    {
        label: 'Photographer',
        value: 3,
    }
];

export const UserTypePicker = (props: UserTypePickerProps) => {
    const {value: val, onSelect} = props;

    const [value, setValue] = useState<UserTypeOptionType>(val);

    return (
        <Box sx={{width: 300, height: 50, display: 'flex'}}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as UserTypeOptionType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="user-type-picker"
                options={data ?? [] as UserTypeOptionType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props} key={option?.value}>{option?.label}</li>}
                sx={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label="Search User Type"/>
                )}
            />
        </Box>
    );
}
