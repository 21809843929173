import React, {useState, useEffect} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableContainer, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WifiIcon from '@mui/icons-material/Wifi';
import {useDataProvider} from "ra-core";
import {useNotify} from "react-admin";
import type {WifiItem} from './types';
import {WifiHiddenPassword} from "./WifiHiddenPassword";
import {WifiDelete} from "./WifiDelete";

export const WifiConfig = ({record}: any) => {
    const notify = useNotify();
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newSSID, setNewSSID] = useState<string>('');
    const [wifisList, setWifisList] = useState<Array<WifiItem>>([]);
    const [selectedWifi, setSelectedWifi] = useState<number>(-1);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const dataProvider = useDataProvider();

    useEffect(() => {

        async function fetchUserWifiList() {
            try {
                const res = await dataProvider.saGet(`User/${record.id}/WiFi`, {});
                const wifiList = res.data.payload;
                const selectedWifi = wifiList.find((row: WifiItem) => row.isActive === true);
                if (selectedWifi) {
                    setSelectedWifi(selectedWifi.wiFiParameterId);
                }
                setWifisList(wifiList);
            } catch (e) {
                notify("Fetching the wifi list failed", {type: 'error'});
            }
        }

        dialogIsOpen && fetchUserWifiList();

    }, [record.id, dataProvider, notify, dialogIsOpen]);

    const handleActivateWifi = async (wiFiParameterId: number) => {
        if (wiFiParameterId === selectedWifi) {
            try {
                await dataProvider.saDelete(`/User/WiFi/${wiFiParameterId}/Activate`, {});
                notify("The wifi configuration was updated successfully", {type: 'success'});
                setSelectedWifi(-1);
            } catch (e) {
                notify("Updating the wifi configuration failed", {type: 'error'});
            }
        } else {
            try {
                await dataProvider.saPost(`/User/WiFi/${wiFiParameterId}/Activate`, {});
                notify("The wifi configuration was updated successfully", {type: 'success'});
                setSelectedWifi(wiFiParameterId);
            } catch (e) {
                notify("Updating the wifi configuration failed", {type: 'error'});
            }
        }
    }

    const handleClick = () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        return;
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleAddWifi = async () => {
        if (newName.length === 0 || newSSID.length === 0 || newPassword.length === 0) {
            notify("Please fill all the fields", {type: 'error'});
            return;
        }

        try {
            const res = await dataProvider.saPut(`User/${record.id}/WiFi?Name=${newName}&SSID=${newSSID}&Password=${newPassword}`, {});
            const wifi = res.data.payload;
            const newWifisList = [...wifisList,
                { isActive: false, name: wifi.name, password: wifi.password, ssid: wifi.ssid, wiFiParameterId: wifi.wiFiParameterId }];
            setWifisList(newWifisList);
            setNewName('');
            setNewSSID('');
            setNewPassword('');
            notify("The wifi configuration was added successfully", {type: 'success'});
        } catch (e) {
            notify("Adding the wifi configuration failed", {type: 'error'});
        }
    }

    return (
        <>
            <Tooltip title="Wifi config">
                <IconButton onClick={handleClick}>
                    <WifiIcon/>
                </IconButton>
            </Tooltip>

            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="wifi-dialog-title"
                aria-describedby="wifi-dialog-description"
            >
                <DialogTitle id="wifi-dialog-title" sx={{ marginBottom: 0 }}>
                    {"Wifi Config"}
                </DialogTitle>
                <DialogContent>
                    {wifisList.length > 0 && (
                        <TableContainer component={Paper} style={{borderTop: 'solid 1px #e7e7e7'}} sx={{ marginBottom: 4 }}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ width: 100, padding: 0 }}>Name</TableCell>
                                        <TableCell align="center" sx={{ width: 100, padding: 0 }}>SSID</TableCell>
                                        <TableCell align="center" sx={{ width: 100, padding: 0 }}>Password</TableCell>
                                        <TableCell align="center" sx={{ width: 10, padding: 0 }}>Active</TableCell>
                                        <TableCell align="center" sx={{ width: 10, padding: 0 }}></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {wifisList.map((row) => (
                                        <TableRow
                                            key={`row-${row.wiFiParameterId}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" component="th" scope="row" sx={{ minWidth: 100 }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="center">{row.ssid}</TableCell>
                                            <TableCell align="center">
                                                <WifiHiddenPassword password={row.password} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: 10 }}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={selectedWifi === row.wiFiParameterId}
                                                    onChange={() => handleActivateWifi(row.wiFiParameterId)}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: 10 }}>
                                                <WifiDelete
                                                    wiFiParameterId={row.wiFiParameterId}
                                                    selectedWifi={selectedWifi}
                                                    setSelectedWifi={setSelectedWifi}
                                                    setWifisList={setWifisList}
                                                    wifisList={wifisList}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    <Typography variant="h6" sx={{marginBottom: 1, fontSize: '1rem'}}>
                        Add a wifi configuration
                    </Typography>

                    <TableContainer component={Paper}  style={{borderTop: 'solid 1px #e7e7e7'}}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">SSID</TableCell>
                                    <TableCell align="center">Password</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <OutlinedInput
                                            onChange={(e) => setNewName(e.target.value)}
                                            size="small"
                                            sx={{ width: 100, height: 28 }}
                                            value={newName}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <OutlinedInput
                                            onChange={(e) => setNewSSID(e.target.value)}
                                            size="small"
                                            sx={{ width: 100, height: 28 }}
                                            value={newSSID}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <OutlinedInput
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ?
                                                            <VisibilityOffIcon sx={{ fontSize: 14 }} />
                                                            : <VisibilityIcon sx={{ fontSize: 14 }} />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            size="small"
                                            sx={{ width: 100, height: 28, paddingRight: 0 }}
                                            type={showPassword ? "text" : "password"}
                                            value={newPassword}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            onClick={handleAddWifi}
                                            startIcon={<AddIcon />}
                                            size="small"
                                            variant="contained"
                                        >
                                            <div
                                                style={{ textTransform: 'capitalize', marginRight: '3px', marginLeft: '-8px', height: '18px', lineHeight: '19px' }}
                                            >Add</div>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={handleDialogClose} size="small" autoFocus>Done</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}