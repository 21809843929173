
export const dateToString = (date : Date) => {
    return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear() + " " +
        String(date.getHours()).padStart(2, '0') + ":" +
        String(date.getMinutes()).padStart(2, '0') + "." +
        String(date.getSeconds()).padStart(2, '0');
}

export const unixTimestampToString = (unixTimestamp: number) => {
    return dateToString(new Date(unixTimestamp * 1000));
}