import { useState } from "react";

import FormatPaint from "@mui/icons-material/FormatPaint";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useDataProvider } from "ra-core";
import {
  BooleanInput,
  NumberInput,
  SimpleForm,
  TextInput,
  useNotify,
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { ImageStyleSelectInput } from "../../Inputs/ImageStyleSelectInput";
import { AdvancedParameters } from "../Image/types";
import { EventType } from "./types";

export type UpdateStyleActionProps = {
  record: EventType;
};

export const UpdateStyleAction = ({ record }: UpdateStyleActionProps) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const { mutateAsync: UpdateStyleEvent, isLoading: isLoadingDeleteEvent } =
    useMutation(
      () => {
        const eventId = record.eventId;
        if (!eventId) {
          return;
        }
        const eventEditParams = {
          Style: selectedStyle,
          AutoEnhance: selectedAutoEnhance,
          Intensity: selectedIntensity,
          advancedParameters: advancedParamsEnabled ? advancedParameters : null
        };
        return dataProvider.saPost(
          `Event/${eventId}/Styles/Edit`,
          eventEditParams
        );
      },
      {
        onSuccess: (data) => {
          notify("Event " + record.name + " resend to editing successfully", {
            type: "success",
          });
        },
        onError: () => {
          notify("Event " + record.name + " resend to editing failed", {
            type: "error",
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries("Event");
        },
      }
    );

  const handleClick = () => {
    setDialogIsOpen(true);
    return;
  };

  const handleDialogOk = async () => {
    setDialogIsOpen(false);
    await UpdateStyleEvent();
    return;
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
    return;
  };

  const [selectedStyle, setSelectedStyle] = useState<number>(0);
  const [selectedAutoEnhance, setSelectedAutoEnhance] = useState<boolean>(true);
  const [selectedIntensity, setSelectedIntensity] = useState<number | null>(
    null
  );
  const [advancedParameters, setAdvancedParameters] =
    useState<AdvancedParameters>({
      cropping: false,
      autoRotate: false,
      blanketRetouch: false,
      faceRetouch: false,
      rotateDegrees: 45,
    });
  const [advancedParamsEnabled, setAdvancedParamsEnabled] =
    useState<boolean>(false);

  return (
    <>
      <Tooltip title="Resend event to editing">
        {isLoadingDeleteEvent ? (
          <CircularProgress />
        ) : (
          <IconButton onClick={handleClick}>
            <FormatPaint />
          </IconButton>
        )}
      </Tooltip>
      <Dialog
        open={dialogIsOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Resend event " + record.name + " to editing?"}
        </DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={false}>
            <ImageStyleSelectInput
              defaultValue={0}
              onChange={setSelectedStyle}
              source="style"
              label="Image style"
            />
            <BooleanInput
              defaultValue={true}
              onChange={(e) => setSelectedAutoEnhance(e.target.checked)}
              source="autoEnhance"
              label="Auto enhance"
            />
            <TextInput
              defaultValue=""
              onChange={(e) => setSelectedIntensity(parseInt(e.target.value))}
              source="intensity"
              label="Intensity"
            />
            <BooleanInput
              source="sendAdvancedParameters"
              label="Advanced parameters"
              defaultValue={advancedParamsEnabled}
              onChange={(e) => setAdvancedParamsEnabled(e.target.checked)}
            ></BooleanInput>

            <Box
              sx={{
                margin: 2,
                padding: 1,
                border: "3px solid #ccc",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <BooleanInput
                disabled={!advancedParamsEnabled}
                id="cropping"
                source="cropping"
                label="Cropping"
                onChange={(e) => advancedParameters["cropping"] = e.target.checked}
                defaultValue={advancedParameters["cropping"]}
              ></BooleanInput>
              <BooleanInput
                disabled={!advancedParamsEnabled}
                source="FaceRetouch"
                label="Face retouch"
                onChange={(e) => advancedParameters["faceRetouch"] = e.target.checked}
              ></BooleanInput>
              <BooleanInput
                disabled={!advancedParamsEnabled}
                source="BlanketRetouch"
                label="Blanket retouch"
                onChange={(e) => advancedParameters["blanketRetouch"] = e.target.checked}
              ></BooleanInput>
              <BooleanInput
                disabled={!advancedParamsEnabled}
                source="AutoRotate"
                label="Auto rotate"
                onChange={(e) => advancedParameters["autoRotate"] = e.target.checked}
              ></BooleanInput>
              <NumberInput
                disabled={!advancedParamsEnabled}
                defaultValue={advancedParameters["rotateDegrees"]}
                source="RotateDegrees"
                label="Rotate degrees"
                onChange={(e) => advancedParameters["rotateDegrees"] = parseInt(e.target.value)}
              ></NumberInput>
            </Box>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogOk} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
