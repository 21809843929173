import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

type AccountingObjectType = {
    id: number,
    name: string
};

export const useAccountingObjectTypes = () => {

    const [accountingObjectTypes, setAccountingObjectTypes] = useState<AccountingObjectType[]>([]);

    const getAccountingObjectType = (accountingObjectType: number) => {
        return accountingObjectTypes.find(u => u.id === accountingObjectType)?.name;
    }

    const getAccountingObjectTypeNames = () => {
        return accountingObjectTypes.map(u => u.name);
    }

    const getAccountingObjectTypeId = (name: string) => {
        return accountingObjectTypes.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: AccountingObjectType[] }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'AccountingObjectTypes',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
                overridePath: 'Accounting/ObjectTypes'
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setAccountingObjectTypes(data);
        }
    }, [data, isLoading]);

    return {
        accountingObjectTypes: accountingObjectTypes,
        getAccountingObjectType: getAccountingObjectType,
        getAccountingObjectTypeNames: getAccountingObjectTypeNames,
        isLoading: isLoading,
        getAccountingObjectTypeId: getAccountingObjectTypeId,
    }
}