import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface SimpleBackdropProps {
    open: boolean;
    handleClose?: () => void;
}

const SimpleBackdrop = (props: SimpleBackdropProps) => {
    const {open, handleClose} = props;
    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
            onClick={handleClose}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
}

export default SimpleBackdrop;
