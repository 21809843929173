import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { useRecordContext } from "ra-core";
import { useState } from "react";
import { useImageConfig } from "../../context/ImageConfigContext";

interface ImageFieldProps {
  imageType: string;
  label?: string;
}

export const ImageField = (props: ImageFieldProps) => {
  const record = useRecordContext(props);
  const imageType = props.imageType;
  const { getImageUrl } = useImageConfig();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = async () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const thumbnailUrl = getImageUrl(
    record.fileName,
    record.thumbnailFileName,
    imageType,
    "Thumbnail",
    record.rawPath
  );

  const imageUrl = getImageUrl(
    record.fileName,
    record.thumbnailFileName,
    imageType,
    "Large",
    record.rawPath
  );

  const displayName = props.label ?? record.imageName;

  return (
    <>
      <img
        src={thumbnailUrl}
        alt={displayName}
        style={{
          height: "50px",
          cursor: "pointer",
          objectFit: "cover",
          border: "3px solid #ccc",
        }}
        onMouseOver={openModal}
        onMouseOut={closeModal}
      />

      <Modal
        open={!!modalIsOpen}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        style={{
          pointerEvents: "none",
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height="100vh"
        >
          <img
            src={imageUrl} // Display the original image here
            alt={record.name}
            style={{
              height: "90vh",
              objectFit: "contain",
              border: "3px solid #ccc",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
