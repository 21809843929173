import React, {useState} from "react";
import {useDataProvider} from "ra-core";
import {useNotify} from "react-admin";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import type {WifiItem} from './types';

export const WifiDelete = ({wiFiParameterId, selectedWifi, setSelectedWifi, setWifisList, wifisList}: {
    wiFiParameterId: number, selectedWifi: number, setSelectedWifi: (id: number) => void, setWifisList: (wifisList: Array<WifiItem>) => void, wifisList: Array<WifiItem>
}) => {
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleOpenDialog = () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDelete = async () => {
        try {
            await dataProvider.saDelete(`User/WiFi/${wiFiParameterId}`, {});
            const newWifisList = wifisList.filter((wifiObj) => wifiObj.wiFiParameterId !== wiFiParameterId);
            setWifisList(newWifisList);
            if (wiFiParameterId === selectedWifi) {
                setSelectedWifi(-1);
            }
            notify("The wifi configuration was deleted successfully", {type:'success'});
            setDialogIsOpen(false);
        } catch (e) {
            notify("Deleting the wifi configuration failed", {type: 'error'});
        }
    };

    const handleCloseDialog = () => {
        setDialogIsOpen(false);
    }

    return (
        <>
            <IconButton onClick={handleOpenDialog}>
                <DeleteTwoTone/>
            </IconButton>

            <Dialog
                open={dialogIsOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete wifi configuration ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this wifi?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};