import React from 'react';
import CircularProgress, {
    CircularProgressProps,
    CircularProgressPropsColorOverrides
} from "@mui/material/CircularProgress/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {OverridableStringUnion} from "@mui/types";

export const ProgressIndicator = (props: CircularProgressProps & { value: number }) => {

    let color: OverridableStringUnion<
        'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
        CircularProgressPropsColorOverrides
        > = 'error';
    if (props.value > 80) {
        color = 'success';
    } else if (props.value > 25) {
        color = 'warning';
    }

    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" {...props} color={color} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}
