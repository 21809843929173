import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDataProvider } from "ra-core";
import {
  EditContextProvider,
  useGetList,
  useNotify,
  useRedirect,
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import SimpleBackdrop from "../../SimpleBackdrop";
import { UpsertB2BUserForm } from "./UpsertB2BUserForm";
import { B2BUser, B2BUserUpdateInput } from "./types";

export const B2BUserUpdate = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const { id: b2BUserId } = useParams();

  const { data, isLoading, error } = useGetList("B2BUsers", {
    filter: { Limit: 1, Offset: 0, B2BUserId: b2BUserId },
    meta: {
      alternativeId: "b2BUserId",
      pagination: true,
      dataExtractFunction: (data: { payload: { results: B2BUser[] } }) => {
        return data.payload.results;
      },
    },
  });

  const { mutateAsync: updateB2BUser, isLoading: isLoadingUpdateUser } =
    useMutation(
      (input: B2BUserUpdateInput) => {
        const dataProvided = dataProvider.saPost(
          `B2BUsers/${b2BUserId}?Name=${encodeURIComponent(input.name)}` +
            `&Email=${encodeURIComponent(input.email)}` +
            `&Phone=${encodeURIComponent(input.phone)}` +
            `&IsBlocked=${input.isBlocked}` +
            `&BlobConnection=${encodeURIComponent(input.blobConnection)}` +
            `&BlobStorageBaseURL=${encodeURIComponent(input.blobStorageBaseURL)}` +
            `&UseWebhook=${input.useWebhook}` +
            `&WebhookUrl=${encodeURIComponent(input.webhookUrl)}` +
            `&WebhookAuthenticationType=${input.webhookAuthenticationType}` +
            `&WebhookCredentials=${encodeURIComponent(
              input.webhookCredentials
            )}` +
            `&AlgoEndpoint=${encodeURIComponent(input.algoEndpoint)}` +
            `&AlgoEndpointUsername=${encodeURIComponent(
              input.algoEndpointUsername
            )}` +
            `&AlgoEndpointPassword=${encodeURIComponent(
              input.algoEndpointPassword
            )}`
        );
        return dataProvided;
      },
      {
        onSuccess: async (data: {
          data: { code: number; message: string };
        }) => {
          if (data.data.code > 0) {
            notify(`API User update failed - ${data.data.message}`, {
              type: "error",
            });
          } else {
            notify("API User updated successfully", { type: "success" });
            redirect("list", "B2BUser");
          }
        },
        onError: (data) => {
          notify("API User update failed", { type: "error" });
        },
        onSettled: () => {
          queryClient.invalidateQueries(`B2BUser`);
        },
      }
    );

  if (isLoading) {
    return <SimpleBackdrop open={isLoading} />;
  }

  if (error || !data || !data.length) {
    redirect("list", "User");
    return <></>;
  }

  const obj = data[0];
  const record = {
    name: obj.name,
    email: obj.email,
    phone: obj.phone,
    isBlocked: obj.isBlocked,
    blobConnection: obj.blobConnection,
    blobStorageBaseURL: obj.blobStorageBaseURL,
    useWebhook: obj.useWebhook,
    webhookUrl: obj.webhookUrl,
    webhookAuthenticationType: obj.webhookAuthenticationType,
    webhookCredentials: obj.webhookCredentials,
    algoEndpoint: obj.algoEndpoint,
    algoEndpointUsername: obj.algoEndpointUsername,
    algoEndpointPassword: obj.algoEndpointPassword,
    ...obj,
  };

  if (!record) {
    redirect("list", "B2BUser");
    return <></>;
  }

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ marginTop: 5 }}>
        Edit API User - {b2BUserId}
      </Typography>
      <SimpleBackdrop open={isLoadingUpdateUser} />
      <Card>
        <EditContextProvider
          value={{
            data: record,
            record: record,
            defaultTitle: "Edit API User",
            resource: "User",
            isLoading: isLoading,
            isFetching: isLoading,
            redirect: "list",
            // @ts-ignore
            refetch: () => {},
          }}
        >
          <UpsertB2BUserForm
            onSubmit={(e) => updateB2BUser(e as B2BUserUpdateInput)}
            isUpdate={true}
          />
        </EditContextProvider>
      </Card>
    </>
  );
};
