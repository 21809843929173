import React, {useState} from 'react';

import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {ZipArchiveMarksPickerType} from "../../types";

export type ZipArchiveMarksType = ZipArchiveMarksPickerType | null;

type ZipArchiveMarksPickerProps = {
    value: ZipArchiveMarksType;
    onSelect: (value: ZipArchiveMarksType) => void;
}

const data = [
    {
        label: 'Selected',
        value: 1,
    },
    {
        label: 'All Images',
        value: 2,
    }
];

export const ZipArchiveMarksPicker = (props: ZipArchiveMarksPickerProps) => {
    const {value: val, onSelect} = props;

    const [value, setValue] = useState<ZipArchiveMarksType>(val);

    return (
        <Box sx={{width: 300, height: 50, display: 'flex'}}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as ZipArchiveMarksType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="zip-archive-marks-type-picker"
                options={data ?? [] as ZipArchiveMarksType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props} key={option?.value}>{option?.label}</li>}
                sx={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label="Zip Archive Images Marks"/>
                )}
            />
        </Box>
    );
}
