import { useDataProvider } from "ra-core";
import {
  BooleanInput,
  NumberInput,
  SimpleForm,
  TextInput,
  email,
  regex,
  required,
} from "react-admin";
import { useQueryClient } from "react-query";
import { UserCreateInput, UserUpdateInput } from "../../../types";
import { AiCullingSensitivitySelectInput } from "../../Inputs/AiCullingSensitivitySelectInput";
import { CountryTypeSelectInput } from "../../Inputs/CountrySelectInput";
import { ImageStyleSelectInput } from "../../Inputs/ImageStyleSelectInput";
import { UserImageUploader } from "../../Inputs/UserImageUploader";
import { UserTypeSelectInput } from "../../Inputs/UserTypeSelectInput";
import {EventTypeSelectInput, UserSelectInput} from "../../Inputs";

interface UpsertUserFormProps {
  onSubmit: (input: UserUpdateInput | UserCreateInput) => void;
  isUpdate: boolean;
}

export const UpsertUserForm = (props: UpsertUserFormProps) => {
  const { onSubmit, isUpdate } = props;
  const validatePhoneNumber = [
    required(),
    regex(/^\+(\d){6,14}$/, "Invalid Phone Number. Example: +97254..."),
  ];
  const validateEmail = [required(), email()];
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  return (
    <SimpleForm
      onSubmit={async ({ userImagesBase, ...formValues }) => {
        if (userImagesBase && isUpdate) {
          try {
            const { data } = await dataProvider.saPostFile(
              `/UserImage/${formValues.id}`,
              {},
              userImagesBase
            );
            await dataProvider.saPost(
              `UserImage/ProfileImage/${formValues.id}?UserImageId=${data.payload.userImageId}`
            );
          } catch (e) {
            console.log(e);
          } finally {
            delete formValues.userImagesBase;
            queryClient.invalidateQueries("User");
          }
        }
        await onSubmit(formValues as UserCreateInput);
        return;
      }}
      sx={{ width: "50%" }}
    >
      {isUpdate && <UserImageUploader source="userImagesBase" />}
      <TextInput source="firstName" validate={[required()]} fullWidth />
      <TextInput source="lastName" validate={[required()]} fullWidth />
      <TextInput source="email" validate={validateEmail} fullWidth />
      <UserTypeSelectInput source="userType" fullWidth />
      {!isUpdate && (
        <TextInput
          source="phoneNumber"
          validate={validatePhoneNumber}
          fullWidth
        />
      )}
      {isUpdate && (
        <BooleanInput
          label="Allow user to upload images"
          source="allowWGUpload"
        />
      )}
      <AiCullingSensitivitySelectInput
        label="AI Culling Sensitivity"
        source="aiCullingSensitivity"
        fullWidth
      />
      <ImageStyleSelectInput
        validate={[required()]}
        source="style"
        label="Select Image Style"
      />
      <BooleanInput source="autoEnhance" label="Auto Enhance"></BooleanInput>
      {isUpdate && (
        <>
          <BooleanInput
            label="Enable subscriptions for the user"
            source="enableSubscriptions"
          />
          <TextInput
            label="Stripe customer ID"
            source="stripeId"
            disabled
            fullWidth
          />
          <TextInput
            label="Stripe subscription ID"
            source="stripeSubscriptionId"
            disabled
            fullWidth
          />
          <NumberInput
            label="Stripe trial limit"
            source="trialLimit"
            fullWidth
          />
          <BooleanInput
            label="Email Verified ?"
            source="isEmailVerified"
            fullWidth
          />
        </>
      )}
      <CountryTypeSelectInput source="countryId" fullWidth />
      {!isUpdate && (
        <>
          <TextInput
            label="Hubspot UTM name"
            source="hubspotUTMName"
            fullWidth
          />
          <TextInput
            label="Hubspot Lead source"
            source="hubspotLeadSource"
            fullWidth
          />
        </>
      )}
      <TextInput label="Company" source="company" fullWidth />
      <TextInput source="jpegOutputQuality" fullWidth/>
      <EventTypeSelectInput source="defaultEventTypeId" label={"Default event Type"} fullWidth/>
    </SimpleForm>
  );
};
