import {AutocompleteInput, AutocompleteInputProps} from "react-admin";
import { useLightroomPresetStatuses } from "../Lists/User/useLightroomPresetStatuses";

export const LightroomPresetStatusSelectInput = (props: AutocompleteInputProps) => {
    const {lightroomPresetStatuses, isLoading} = useLightroomPresetStatuses();

    return (
        <AutocompleteInput
            label={props.label}
            source={props.source}
            choices={lightroomPresetStatuses}
            isLoading={isLoading}
            isFetching={isLoading}
            onChange={props.onChange}
        />
    );
}
