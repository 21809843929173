import {FunctionField} from "react-admin";
import { EditImageStyleAction } from "./EditImageStyleAction";

export const ImageStyleActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: any) => {
                    return (
                        <>
                            <EditImageStyleAction record={record}/>
                        </>
                    );
                }
            }
        />
    );
};
