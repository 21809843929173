import Box from "@mui/material/Box";
import { FunctionField } from "react-admin";
import { EditB2BUser } from "./EditB2BUserAction";
import { B2BUser } from "./types";
import { Sync2CacheB2BUser } from "./Sync2CacheB2BUser"

export const ActionsField = () => {
  return (
    <FunctionField
      label={"Actions"}
      render={(record: B2BUser) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {
              <>
                <EditB2BUser record={record} />
                <Sync2CacheB2BUser record={record} />
              </>
            }
          </Box>
        );
      }}
    />
  );
};
