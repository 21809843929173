import { BooleanField, BooleanInput, Datagrid, FunctionField, List, Pagination, TextField, TextInput, WrapperField } from "react-admin";
import { LightroomPreset } from "./types";
import { unixTimestampToString } from "../../../utils/date";
import { UserPopoverField } from "../../Popover";
import { useState } from "react";
import { ActionsField } from "./ActionsField";
import { useLightroomPresetStatuses } from "../User/useLightroomPresetStatuses";
import { UserSelectInput } from "../../Inputs";
import { LightroomPresetStatusSelectInput } from "../../Inputs/LightroomPresetStatusSelectInput";

const lightroomPresetListFilters = [
    <TextInput
        label="Preset ID"
        source="lightroomPresetId"
        alwaysOn
    />,
    <TextInput
        label={"Preset name"}
        source={"Name"}
        alwaysOn
    />,
    <UserSelectInput
        label={"Creator User"}
        source={"userId"}
    />,
    <BooleanInput
        label={"Deleted?"}
        source={"IsDeleted"}
    />,
    <BooleanInput
        label={"Global?"}
        source={"IsGlobal"}
    />,
    <LightroomPresetStatusSelectInput
        label={"Status"}
        source={"status"}
        alwaysOn
    />,
];

export const LightroomPresetList = () => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentLightroomPresetId, setCurrentLightroomPresetId] = useState<number | null>(null);
    const { getLightroomPresetStatus } = useLightroomPresetStatuses();

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, currentLightroomPresetId: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentLightroomPresetId(currentLightroomPresetId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentLightroomPresetId(null);
    };

    return (
        <>
            <List empty={false}
                title="Lightroom presets"
                queryOptions={{
                    meta: {
                        pagination: true,
                        dataExtractFunction: (data: { payload: { results: LightroomPreset[] } }) => {
                            return data.payload.results;
                        },
                        overridePath: `LightroomPresets`
                    },
                    refetchOnMount: true,
                }}
                filter= {{'OrderByDesc': true}}
                perPage={100}
                pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]} />}
                filters={lightroomPresetListFilters}
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField sortable={false} label="ID" source="lightroomPresetId"/>
                    <TextField sortable={false} label="Name" source="name"/>
                    <FunctionField label="Status" render={(record: LightroomPreset) => {
                        return getLightroomPresetStatus(record.status);
                    }}/>
                    <WrapperField source={'user'}>
                        <UserPopoverField
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                            currentResourceId={currentLightroomPresetId}
                            resourceIdKey={'lightroomPresetId'}
                            resource={'user'}
                        />
                    </WrapperField>
                    <FunctionField label="Uploaded" render={(record: LightroomPreset) => {
                        return unixTimestampToString(record.created);
                    }}/>
                    <FunctionField label="Last modified" render={(record: LightroomPreset) => {
                        return unixTimestampToString(record.modified);
                    }}/>
                    <FunctionField label="Size" render={(record: LightroomPreset) => {
                        return record.presetSize + " Bytes";
                    }}/>
                    <TextField sortable={false} label="Base HALD name" source="baseHaldName"/>
                    <FunctionField label="Preset link" render={(record: LightroomPreset) => {
                        return <a href={record.presetUrl}>Download</a>
                    }}/>
                    <FunctionField label="HALD link" render={(record: LightroomPreset) => {
                        return <a href={record.haldUrl}>{record.haldUrl === null ? "N/A" : "Download"}</a>
                    }}/>
                    <BooleanField sortable={false} label="Deleted?" source="isDeleted"/>
                    <ActionsField/>
                </Datagrid>
            </List>

        </>
    );
};
