import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

type ImageProcessingStageValue = {
    id: number,
    name: string
};

export const useImageProcessingStageValues = () => {

    const [imageProcessingStageValues, setImageProcessingStageValues] = useState<ImageProcessingStageValue[]>([]);

    const getImageProcessingStageValue = (imageProcessingStageValue?: number) => {
        if (imageProcessingStageValue === null) {
            return "N/A";
        }
        return imageProcessingStageValues.find(u => u.id === imageProcessingStageValue)?.name;
    }

    const getImageProcessingStageValueNames = () => {
        return imageProcessingStageValues.map(u => u.name);
    }

    const getImageProcessingStageValueId = (name: string) => {
        return imageProcessingStageValues.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: ImageProcessingStageValue[] }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'Image/ImageProcessingStage/StageValues',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setImageProcessingStageValues(data);
        }
    }, [data, isLoading]);

    return {
        imageProcessingStageValues: imageProcessingStageValues,
        getImageProcessingStageValue: getImageProcessingStageValue,
        getImageProcessingStageValueNames: getImageProcessingStageValueNames,
        isLoading: isLoading,
        getImageProcessingStageValueId: getImageProcessingStageValueId,
    }
}