import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

type AccountingActionType = {
    id: number,
    name: string
};

export const useAccountingActionTypes = () => {

    const [accountingActionTypes, setAccountingActionTypes] = useState<AccountingActionType[]>([]);

    const getAccountingActionType = (accountingActionType: number) => {
        return accountingActionTypes.find(u => u.id === accountingActionType)?.name;
    }

    const getAccountingActionTypeNames = () => {
        return accountingActionTypes.map(u => u.name);
    }

    const getAccountingActionTypeId = (name: string) => {
        return accountingActionTypes.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: AccountingActionType[] }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'AccountingActionTypes',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
                overridePath: 'Accounting/ActionTypes'
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setAccountingActionTypes(data);
        }
    }, [data, isLoading]);

    return {
        accountingActionTypes: accountingActionTypes,
        getAccountingActionType: getAccountingActionType,
        getAccountingActionTypeNames: getAccountingActionTypeNames,
        isLoading: isLoading,
        getAccountingActionTypeId: getAccountingActionTypeId,
    }
}