import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  NumberField,
  Pagination,
  TextField,
  TextInput,
} from "react-admin";
import { isNumber } from "../../../utils";
import { unixTimestampToString } from "../../../utils/date";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { ActionsField } from "./ActionsField";
import { B2BUser } from "./types";

const b2BUserFilters = [
  <TextInput label="Name" source="name" resettable alwaysOn />,
  <TextInput
    label="API user ID"
    source="b2BUserId"
    validate={[
      (value: string) =>
        value === null || isNumber(value) ? null : "Must be a number",
    ]}
    resettable
    alwaysOn
  />,
];

export const B2BUserList = () => {
  return (
    <List
      title="API users"
      filterDefaultValues={{ b2bUserId: null }}
      queryOptions={{
        meta: {
          alternativeId: "b2BUserId",
          pagination: true,
          dataExtractFunction: (data: { payload: { results: B2BUser[] } }) => {
            return data.payload.results;
          },
          overridePath: "B2BUsers",
        },
      }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
      filters={b2BUserFilters}
    >
      <ListFilterCleaner>
        <Datagrid bulkActionButtons={false}>
          <TextField sortable={false} label="ID" source="b2BUserId" />
          <TextField sortable={false} label="Name" source="name" />
          <FunctionField
            label="Created"
            render={(record: B2BUser) => {
              return unixTimestampToString(record.created);
            }}
          />
          <TextField sortable={false} source="phone" />
          <TextField sortable={false} source="email" />
          <FunctionField
            label="API token"
            render={(record: B2BUser) => {
              return record.token.substring(0, 12) + "...";
            }}
          />
          <BooleanField label="Blocked?" sortable={false} source="isBlocked" />
          <FunctionField
            label="BLOB storage connection string"
            render={(record: B2BUser) => {
              const value = record.blobConnection ? (record.blobConnection.substring(0, 12) + "...") : "N/A";
              return <p title={record.blobConnection}>{value}</p>;
            }}
          />
          <FunctionField
            label="BLOB storage base URL"
            render={(record: B2BUser) => {
              const value = record.blobStorageBaseURL ? (record.blobStorageBaseURL.substring(0, 12) + "...") : "N/A";
              return <p title={record.blobStorageBaseURL}>{value}</p>;
            }}
          />
          <BooleanField
            label="Use webhook?"
            sortable={false}
            source="useWebhook"
          />
          <TextField label="Webhook URL" sortable={false} source="webhookUrl" />
          <NumberField
            label="Webhook authentication type"
            sortable={false}
            source="webhookAuthenticationType"
          />
          <TextField
            label="Webhook credentials"
            sortable={false}
            source="webhookCredentials"
          />
          <TextField
            sortable
            label="Algorithms endpoint URL"
            source="algoEndpoint"
          />
          <TextField
            sortable
            label="Algorithms endpoint Username"
            source="algoEndpointUsername"
          />
          <TextField
            sortable
            label="Algorithms endpoint Password"
            source="algoEndpointPassword"
          />
          <ActionsField />
        </Datagrid>
      </ListFilterCleaner>
    </List>
  );
};
