import {BooleanField, Datagrid, List, TextField, TextInput} from "react-admin";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import { StripePricesActionsField } from "./StripePricesActionsField";

const systemParameterFilters = [
    <TextInput
        label="Parameter name"
        source="paramName"
        resettable
    />,
];

export const StripePricesList = () => (
    <List
        title="System parameters"
        queryOptions={{
            meta: {
                pagination: true,
                dataExtractFunction: (data: { payload: any }) => {
                    return data.payload.results;
                },
                overridePath: 'StripePrices'
            },
            refetchOnMount: true,
        }}
        perPage={25}
        filters={systemParameterFilters}
    >
        <ListFilterCleaner>
            <Datagrid bulkActionButtons={false}>
                <TextField sortable={false} label="id" source="id"/>
                <TextField sortable={false} label="Price id" source="priceId"/>
                <TextField sortable={false} label="Price name" source="priceName"/>
                <BooleanField sortable={false} label="Offered ?" source="isOffered"/>
                <StripePricesActionsField/>
            </Datagrid>
        </ListFilterCleaner>
    </List>
);
