import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";
import { ImageStyle } from "../ImageStyle/types";


export const useImageStyles = () => {

    const [imageStyles, setImageStyles] = useState<ImageStyle[]>([]);

    const getImageStyle = (imageStyleID: number) => {
        const imageStyle : ImageStyle | undefined = imageStyles.find(u => u.id === imageStyleID);
        if (!imageStyle) {
            return "";
        }
        if (imageStyle.lightroomPreset)
        {
            return imageStyle.lightroomPreset.name;
        }
        return imageStyle.name;
    }

    const getImageStyleNames = () => {
        return imageStyles.map(u => u.name);
    }

    const getImageStyleId = (name: string) => {
        return imageStyles.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: { results: ImageStyle[] } }) => {
        return data.payload.results;
    }, []);

    const {data, isLoading} = useGetList(
        'ImageStyles',
        {
            filter: {'Limit': -1, 'Offset': 0, 'OnlyAvailable': true},
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setImageStyles(data);
        }
    }, [data, isLoading]);

    return {
        imageStyles: imageStyles,
        getImageStyle: getImageStyle,
        getImageStyleNames: getImageStyleNames,
        isLoading: isLoading,
        getImageStyleId: getImageStyleId,
    }
}