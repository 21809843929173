import React, {useCallback, useEffect, useState} from 'react';
import {useGetList, AutocompleteInput, AutocompleteInputProps} from "react-admin";

export const UserSelectInput = (props: AutocompleteInputProps & {onlyPhotographer?: boolean}) => {
    const {label, source, onlyPhotographer = false, ...rest} = props;

    const [data, setData] = useState<any[] | undefined>([]);

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload.results;
    }, []);

    const {data: originalData, isLoading, error} = useGetList(
        'user',
        {
            filter: {'Limit': -1, 'Offset': 0, 'IncludeDeleted': false, 'OnlyPhotographers': onlyPhotographer},
            meta: {
                pagination: false,
                alternativeId: 'userId',
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: true,
            queryKey: ['user', 'userSelectInput', onlyPhotographer],
        }
    );

    useEffect(() => {
        if (!data?.length) {
            setData(originalData);
        }
    }, [originalData, data?.length]);

    if (error) {
        return null;
    }

    return (
        <AutocompleteInput
            label={label}
            source={source}
            choices={data?.map((user: any) => ({id: user.userId, name: `${user.firstName} ${user.lastName}`}))}
            isLoading={isLoading}
            isFetching={isLoading}
            {...rest}
        />
    );
}
