import React from "react";
import {
    Create,
    useNotify,
    useRedirect,
} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";
import {UpsertEventForm} from "./UpsertEventForm";
import {EventCreateInput} from "./types";

export const EventCreate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {
        mutateAsync: addEvent,
        isLoading: isLoadingAddEvent
    } = useMutation((input: EventCreateInput) => {
        return dataProvider.saPost(`Event`, input);
    }, {
        onSuccess: async (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`Event creation failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("Event created successfully", {type: 'success'});
                redirect('list', 'Event');
            }
        },
        onError: (data) => {
            notify("Event creation failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`Event`);
        }
    });

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Add Event
            </Typography>
            <SimpleBackdrop open={isLoadingAddEvent}/>
            <Create>
                <UpsertEventForm onSubmit={(e) => addEvent(e as EventCreateInput)} isUpdate={false}/>
            </Create>
        </>
    );
};
