import {DateField} from "react-admin";
import {useRecordContext} from "ra-core";
import get from 'lodash/get';

export const TimestampDateField = (props: any) => {
    const record = useRecordContext(props);
    const value = get(record, props.source);

    const recordWithTimestampAsInteger = {
        [props.source]: value * 1000,
    };
    if (!value) {
        return null;
    }
    return <DateField {...props} record={recordWithTimestampAsInteger} showTime showDate/>
}
