import {
  BooleanInput,
  NumberInput,
  SimpleForm,
  TextInput,
  email,
  regex,
  required,
} from "react-admin";
import { B2BUserCreateInput, B2BUserUpdateInput } from "./types";

interface B2BUpsertB2BUserFormProps {
  onSubmit: (input: B2BUserUpdateInput | B2BUserCreateInput) => void;
  isUpdate: boolean;
}

export const UpsertB2BUserForm = (props: B2BUpsertB2BUserFormProps) => {
  const { onSubmit, isUpdate } = props;
  const validatePhoneNumber = [
    required(),
    regex(/^\+(\d){6,14}$/, "Invalid Phone Number. Example: +97254..."),
  ];
  const validateEmail = [required(), email()];
  return (
    <SimpleForm
      onSubmit={async ({ ...formValues }) => {
        await onSubmit(formValues as B2BUserCreateInput);
        return;
      }}
      sx={{ width: "50%" }}
    >
      <TextInput source="name" validate={[required()]} fullWidth />
      <TextInput source="email" validate={validateEmail} fullWidth />
      <TextInput label="API token" disabled={true} source="token" fullWidth />
      <TextInput source="phone" validate={validatePhoneNumber} fullWidth />
      <BooleanInput label="Blocked" source="isBlocked" fullWidth />
      <TextInput
        label="BLOB storage connection string"
        source="blobConnection"
        fullWidth
      />
      <TextInput
        label="BLOB storage base URL"
        source="blobStorageBaseURL"
        fullWidth
      />
      <BooleanInput label="Use webhook?" source="useWebhook" fullWidth />
      <TextInput label="Webhook URL" source="webhookUrl" fullWidth />
      <NumberInput source="webhookAuthenticationType" fullWidth />
      <TextInput source="webhookCredentials" fullWidth />
      <TextInput
        label="Algorithms endpoint URL"
        source="algoEndpoint"
        fullWidth
      />
      <TextInput
        label="Algorithms endpoint username"
        source="algoEndpointUsername"
        fullWidth
      />
      <TextInput
        label="Algorithms endpoint Password"
        source="algoEndpointPassword"
        fullWidth
      />
    </SimpleForm>
  );
};
