import {config as appConfig} from './config';
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";

// Msal Configurations
const config = {
    auth: {
        authority: `https://login.microsoftonline.com/${appConfig.AAD_TENANT_ID}/`,
        clientId: appConfig.AAD_CLIENT_ID ?? '',
        redirectUri: appConfig.AAD_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
export const loginRequest = {
    scopes: [
        appConfig.AAD_SCOPE ?? ''
    ]
}


export const msalInstance = new PublicClientApplication(config);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
