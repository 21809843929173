import {AutocompleteInput, AutocompleteInputProps} from "react-admin";
import { useCountryTypes } from '../Lists/User/useCountryTypes';

export const CountryTypeSelectInput = (props: AutocompleteInputProps) => {
    const {countryTypes, isLoading} = useCountryTypes();

    return (
        <AutocompleteInput
            label={props.label}
            choices={countryTypes}
            isLoading={isLoading}
            isFetching={isLoading}
            source={props.source}
        />
    );
}
