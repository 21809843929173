import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { unixTimestampToString } from "../../../utils/date";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { OTPTestNumber } from "./types";

const otpTestItemsFilters = [
  <TextInput label="Phone number" source="PhoneNumber" alwaysOn />,
];

export const OTPTestItemsList = () => (
  <List
    title="OTP test numbers"
    queryOptions={{
      meta: {
        alternativeId: "otpTestItemsId",
        pagination: true,
        dataExtractFunction: (data: {
          payload: { results: OTPTestNumber[] };
        }) => {
          return data.payload.results;
        },
      },
      refetchOnMount: true,
    }}
    perPage={25}
    filters={otpTestItemsFilters}
  >
    <ListFilterCleaner>
      <Datagrid bulkActionButtons={false}>
        <TextField label="ID" source="otpTestItemId" />
        <TextField source="phoneNumber" />
        <TextField label="OTP" source="otp" />
        <FunctionField
          label="Created"
          render={(record: OTPTestNumber) => {
            return unixTimestampToString(record.created);
          }}
        />
        <FunctionField
          label="Modified"
          render={(record: OTPTestNumber) => {
            return unixTimestampToString(record.modified);
          }}
        />
      </Datagrid>
    </ListFilterCleaner>
  </List>
);
