import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";
import { useState } from "react";
import { StripePrice } from "./types";

export type DeleteStripePriceActionProps = {
    record: StripePrice;
}

export const DeleteStripePriceAction = ({record}: DeleteStripePriceActionProps) => {
    const notify = useNotify();

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const {mutateAsync: removeStripePrice, isLoading: isLoadingRemoveStripePrice} = useMutation(() => {
        if (!record.priceId) {
            return;
        }
        return dataProvider.saDelete(`StripePrices/${record.id}`, {});
    }, {
        onSuccess: (response: { data: { code: number, message: string } }) => {
            if (response.data.code > 0) {
                notify(`Stripe price deletion failed - ${response.data.message}`, {type: 'error'});
            } else {
                notify("Stripe price deleted successfully", {type: 'success'});
            }
        },
        onError: () => {
            notify("Stripe price deletion failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`StripePrices`);
        }
    });

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await removeStripePrice();
    }

    if (isLoadingRemoveStripePrice) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`Delete stripe price ${record.priceName}`}>
                <IconButton onClick={() => setDialogIsOpen(true)}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
            <Dialog
                open={dialogIsOpen}
                onClose={() => setDialogIsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Delete stripe price ?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Delete stripe price {record.priceName} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogIsOpen(false)}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

