import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDataProvider } from "ra-core";
import {
  EditContextProvider,
  useGetList,
  useNotify,
  useRedirect,
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { UserUpdateInput } from "../../../types";
import SimpleBackdrop from "../../SimpleBackdrop";
import { UpsertUserForm } from "./UpsertUserForm";
import { User } from "./types";

export const UserUpdate = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const { id: userId } = useParams();

  const { data, isLoading, error } = useGetList(
    "user",
    {
      filter: { Limit: 1, Offset: 0, UserId: userId, IncludeDeleted: true },
      meta: {
        alternativeId: "userId",
        pagination: true,
        dataExtractFunction: (data: { payload: { results: User[] } }) => {
          return data.payload.results;
        },
      },
    },
    {
      refetchOnMount: false,
    }
  );

  const { mutateAsync: updateUser, isLoading: isLoadingUpdateUser } =
    useMutation(
      (input: UserUpdateInput) => {
        const dataProvided = dataProvider.saPost(`User/${userId}`, input);
        if (input.allowWGUpload) {
          dataProvider.saPost(`User/${userId}/WGUpload`);
        } else {
          dataProvider.saDelete(`User/${userId}/WGUpload`);
        }
        return dataProvided;
      },
      {
        onSuccess: async (data: {
          data: { code: number; message: string };
        }) => {
          if (data.data.code > 0) {
            notify(`User update failed - ${data.data.message}`, {
              type: "error",
            });
          } else {
            notify("User updated successfully", { type: "success" });
            redirect("list", "User");
          }
        },
        onError: (data) => {
          notify("User update failed", { type: "error" });
        },
        onSettled: () => {
          queryClient.invalidateQueries(`User`);
        },
      }
    );

  if (isLoading) {
    return <SimpleBackdrop open={isLoading} />;
  }

  if (error || !data || !data.length) {
    redirect("list", "User");
    return <></>;
  }

  const obj = data[0];
  const record = {
    firstName: obj.firstName,
    lastName: obj.lastName,
    email: obj.email,
    userType: obj.userType,
    phoneNumber: obj.phoneNumber,
    allowWGUpload: obj.allowWGUpload,
    aiCullingSensitivity: obj.aiCullingSensitivity,
    style: obj.style,
    enableSubscriptions: obj.enableSubscriptions,
    stripeId: obj.stripeId,
    stripeSubscriptionId: obj.stripeSubscriptionId,
    trialLimit: obj.trialLimit,
    countryId: obj.countryId,
    hubspotUTMName: obj.hubspotUTMName,
    hubspotLeadSource: obj.hubspotLeadSource,
    autoEnhance: obj.autoEnhance,
    createdDate: obj.createdDate,
    isEmailVerified: obj.isEmailVerified,
    company: obj.company,
    defaultEventTypeId: obj.defaultEventTypeId,
    ...obj,
  };

  if (!record) {
    redirect("list", "User");
    return <></>;
  }

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ marginTop: 5 }}>
        Edit User - {userId}
      </Typography>
      <SimpleBackdrop open={isLoadingUpdateUser} />
      <Card>
        <EditContextProvider
          value={{
            data: record,
            record: record,
            defaultTitle: "Edit User",
            resource: "User",
            isLoading: isLoading,
            isFetching: isLoading,
            redirect: "list",
            // @ts-ignore
            refetch: () => {},
          }}
        >
          <UpsertUserForm
            onSubmit={(e) => updateUser(e as UserUpdateInput)}
            isUpdate={true}
          />
        </EditContextProvider>
      </Card>
    </>
  );
};
