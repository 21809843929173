import React from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {FunctionField} from "react-admin";
import { unixTimestampToString } from "../../utils/date";

type EventPopoverFieldProps = {
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>, currentEnvironmentId: number) => void;
    handleClose: () => void;
    anchorEl: HTMLButtonElement | null;
    currentResourceId: number | null;
    resourceIdKey: string;
    resource: string;
}

export const EventPopoverField = (props: EventPopoverFieldProps) => {
    const {
        handleOpen,
        handleClose,
        anchorEl,
        currentResourceId,
        resourceIdKey,
        resource
    } = props;

    return (
        <FunctionField label={"Event"} render={(record: any) => {
            if (!record[resource]) {
                return null;
            }
            return (
                <>
                    <Button
                        onClick={(e) => handleOpen(e, record[resourceIdKey])}
                    >
                        {record[resource]?.name}
                    </Button>
                    <Popover
                        id={record[resourceIdKey]}
                        open={currentResourceId === record[resourceIdKey] && Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 1}}>
                            <b>
                                <a href={`/#/Event?filter=${encodeURIComponent(JSON.stringify({ eventId : record[resource]?.eventId }))}`}>
                                Event ID : {record[resource]?.eventId}
                                </a>
                            </b>
                        </Typography>

                        <Typography sx={{p: 1}}><b>Name:</b> {record[resource]?.name}</Typography>
                        <Typography sx={{p: 1}}><b>Start date:</b> {record[resource] && unixTimestampToString(record[resource].startDate)}</Typography>
                        <Typography sx={{p: 1}}><b>End date:</b> {record[resource] && unixTimestampToString(record[resource].endDate)}</Typography>
                    </Popover>
                </>
            );
        }}/>
    );
}
