// ImageConfigContext.js
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';

type GalleryImageSize = 'Thumbnail' | 'Small' | 'Medium' | 'Large' | 'Xlarge';

export interface ImageConfigContextProps {
    originalUrlBase: string,
    editedUrlBase: string,
    alignedUrlBase: string,
    userImagesBase: string,
    rawImagesBase: string,
    autoEnhancedImagesBase: string,
    galleryImageSizesArray: {
        width: number,
        height: number,
        name: GalleryImageSize
    }[]
}

const initialConfigContext: ImageConfigContextProps = {
    originalUrlBase: '',
    editedUrlBase: '',
    alignedUrlBase: '',
    userImagesBase: '',
    rawImagesBase: '',
    autoEnhancedImagesBase: '',
    galleryImageSizesArray: []
}
const ImageConfigContext = createContext<ImageConfigContextProps>(initialConfigContext);

export const useImageConfig = () => {
    const imageConfig = useContext(ImageConfigContext);
    if (!imageConfig) {
        throw new Error('useImageConfig must be used within a ImageConfigProvider');
    }

    const getImageUrl = (fileName?: string, thumbnailFileName?: string, imageType?: string,
        imageSize?: string, rawPath?: string) => {
        if (rawPath && imageType === "raw") {
            return rawPath;
        }
        const imageBase = imageType === "originals" ? imageConfig.originalUrlBase :
            imageType === "aligned" ? imageConfig.alignedUrlBase :
            imageType === "edited" ? imageConfig.editedUrlBase :
            imageType === "raw" ? imageConfig.rawImagesBase :
            imageType === "autoenhanced" ? imageConfig.autoEnhancedImagesBase : ""
        const imageUrl = imageBase + (imageSize ? (imageSize + "/") : "") +
            (imageSize ? thumbnailFileName : fileName);
        return imageUrl;
    }

    return { imageConfig, getImageUrl };
};
type ImageConfigProviderProps = {
    children: ReactNode;
}
export const ImageConfigProvider = ({children}: ImageConfigProviderProps) => {
    const [imageConfig, setImageConfig] = useState<ImageConfigContextProps>(initialConfigContext);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/Image/SystemParameters?Limit=1&Offset=0`).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setImageConfig(data.payload);
                });
            }
        })
    }, []);

    return <ImageConfigContext.Provider value={imageConfig}>{children}</ImageConfigContext.Provider>
}
