import { AutocompleteInput, AutocompleteInputProps } from "react-admin";
import { useAccountingActionTypes } from "../Lists/User/useAccountingActionTypes";

export const AccountingActionTypeSelectInput = (
  props: AutocompleteInputProps
) => {
  const { label, ...rest } = props;

  const { isLoading, accountingActionTypes } = useAccountingActionTypes();

  return (
    <AutocompleteInput
      label={label}
      choices={accountingActionTypes?.map((accountingActioType: any) => ({
        id: accountingActioType.id,
        name:
          accountingActioType.name +
          " (" +
          accountingActioType.id.toString() +
          ")",
      }))}
      isLoading={isLoading}
      isFetching={isLoading}
      sx={{ width: "100%" }}
      {...rest}
    />
  );
};
