import {ChangeEvent, useEffect, useRef, useState} from "react";
import AvatarEditor from "react-avatar-editor";
import {useRecordContext} from "ra-core";
import {useImageConfig} from "../../context/ImageConfigContext";
import {ImageInput, useInput} from "react-admin";
import {useFormContext} from "react-hook-form";


interface ImageProperties {
    originalImage: string | File;
    position: { x: number; y: number };
    scale: number;
}

export const UserImageUploader = (props: any) => {
    const record = useRecordContext();
    const { imageConfig } = useImageConfig();

    const {id} = useInput({
        source: props.source,
        label: props.label,
        onChange: c => console.log(c)
    })
    const formContext = useFormContext();

    const editorRef = useRef<AvatarEditor>(null);

    const image = record?.profileImage ? imageConfig.userImagesBase + record?.profileImage : '/user.png'

    const [imageProperties, setImageProperties] = useState<ImageProperties>({
        originalImage: image,
        position: {x: 0.5, y: 0.5},
        scale: 1,
    });
    const {
        originalImage,
        position,
        scale,
    } = imageProperties;
    useEffect(() => {
        if (editorRef?.current) {
            const canvasScaled: HTMLCanvasElement = editorRef.current.getImageScaledToCanvas();
            fetch(canvasScaled.toDataURL())
                .then((res) => res.blob())
                .then((blob) => {
                    formContext.setValue(props.source, blob )
                });
        }

    }, [imageProperties])
    const handlePositionChange = (position: ImageProperties["position"]) => {
        setImageProperties((prevState) => ({...prevState, position}));
    };

    const handleAdd = (image: any) => {
        setImageProperties((prevState) => ({
            ...prevState,
            originalImage: image
        }));
    };

    const handleZoom = (event: ChangeEvent<any>) => {
        const scale = +event.target.value;
        setImageProperties((prevState) => ({...prevState, scale}));
    };

    return <>

        <AvatarEditor
            borderRadius={140}
            ref={editorRef}
            color={[200, 200, 200, 0.6]}
            scale={scale}
            width={280}
            crossOrigin="anonymous"
            height={280}
            image={originalImage}
            position={position}
            onPositionChange={handlePositionChange}
        />

        <ImageInput source="userImagesBase" label="Profile Image" onChange={handleAdd} id={id}/>

        <div className="row">
            <label htmlFor="reupload">Zoom</label>
            <input
                id="reupload"
                type="range"
                onChange={handleZoom}
                min={1}
                max={5}
                step={0.01}
                defaultValue={scale}

            />
        </div>

    </>
}
