import React, {useState} from "react";

import Popover from "@mui/material/Popover";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";

import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import SendIcon from '@mui/icons-material/Send';
import {useNotify} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import { User } from "./types";

export type ChangeUserPhoneProps = {
    record: User;
}

export const ChangeUserPhone = ({record}: ChangeUserPhoneProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentNewPhoneNumber, setCurrentNewPhoneNumber] = useState<string | null>(null);
    const [currentOTP, setCurrentOTP] = useState<string | null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const dataProvider = useDataProvider();
    const queryClient = useQueryClient();

    const {mutateAsync: changePhoneNumber, isLoading: isLoadingChangePhoneNumber} = useMutation(() => {
        if (!currentNewPhoneNumber || !currentNewPhoneNumber) {
            return;
        }
        return dataProvider.saPost(`User/${record.userId}/ChangePhone?NewPhoneNumber=${encodeURIComponent(currentNewPhoneNumber)}&OTP=${currentOTP}`, {});
    }, {
        onSuccess: (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`Changing user phone number failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("Phone number changed successfully", {type: 'success'});
            }
        },
        onError: () => {
            notify("Changing user phone number failed ", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('User');
        }
    });

    const {mutateAsync: sendOTP, isLoading: isLoadingSendOTP} = useMutation(() => {
        if (!currentNewPhoneNumber) {
            return;
        }
        return dataProvider.saGet(`User/OTP?PhoneNumber=${encodeURIComponent(currentNewPhoneNumber)}`, {});
    }, {
        onSuccess: (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`OTP send failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("OTP sent successfully", {type: 'success'});
            }
        },
        onError: () => {
            notify("OTP send failed", {type: 'error'});
        },
        onSettled: () => {
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangePhoneNumber = () => {
        setDialogIsOpen(true);
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await changePhoneNumber();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingChangePhoneNumber) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`Change Phone Number`}>
                <IconButton onClick={handleOpen}>
                    <PhoneForwardedIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={String(record.userId)}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 400,
                    height: 200,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="h6">Change Phone Number</Typography>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}>
                        <TextField label={"New Phone Number"} value={currentNewPhoneNumber}
                                   onChange={(e) => setCurrentNewPhoneNumber(e.target.value)}/>
                        <Button
                            disabled={!currentNewPhoneNumber}
                            variant="outlined"
                            startIcon={isLoadingSendOTP ? <CircularProgress size={'small'}/> :
                                <SendIcon fontSize={'small'}/>}
                            onClick={() => sendOTP()}
                            size={'small'}
                            sx={{height: 30}}
                        >
                            Send OTP
                        </Button>
                    </Box>
                    <TextField label={"OTP"} value={currentOTP} onChange={(e) => setCurrentOTP(e.target.value)}/>
                    <Button
                        disabled={!currentOTP || !currentNewPhoneNumber}
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onChangePhoneNumber}>
                        Change Phone Number
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change Phone Number?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to change this user phone number?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

