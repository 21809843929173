import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {BooleanInput, SimpleForm, TextInput, useNotify} from "react-admin";
import Typography from "@mui/material/Typography";
import { StripePrice } from "./types";

export type EditStripePriceActionProps = {
    record: StripePrice;
};

export const EditStripePriceAction = ({record}: EditStripePriceActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentStripePriceId, setCurrentStripePriceId] = useState<string>(record.priceId);
    const [currentStripePriceName, setCurrentStripePriceName] = useState<string>(record.priceName);
    const [currentStripePriceIsOffered, setCurrentStripePriceIsOffered] = useState<boolean>(record.isOffered);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setCurrentStripePriceId(record.priceId);
        setCurrentStripePriceName(record.priceName);
        setCurrentStripePriceIsOffered(record.isOffered);
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: updateStripePrice, isLoading: isLoadingUpdateStripePrice } = useMutation(() => {
        return dataProvider.saPost(`StripePrices/${record.id}?PriceId=${currentStripePriceId}&PriceName=${currentStripePriceName}&IsOffered=${currentStripePriceIsOffered}`, {});
    }, {
        onSuccess: data => {
            notify(`Stripe price updated PriceId=${record.priceId} PriceName=${currentStripePriceName}, IsOffered=${currentStripePriceIsOffered}`, { type: 'success' });
        },
        onError: () => {
            notify("Stripe price update failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('StripePrices');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSave = async () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await updateStripePrice();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingUpdateStripePrice) {
        return <CircularProgress />;
    }

    return (
        <>
            <Tooltip title={`Edit stripe price ${record.priceId}`}>
                <IconButton onClick={handleOpen}>
                    <EditIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={record.priceId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 500,
                    height: 330,
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                }}>
                    <Typography sx={{p: 1}}>{`Edit stripe price ${record.priceId}`}</Typography>
                    <SimpleForm toolbar={false} onSubmit={() => setDialogIsOpen(false)}>
                        <TextInput
                            source="priceId"
                            label="Price ID"
                            variant="outlined"
                            onChange={(e) => setCurrentStripePriceId(e.target.value)}
                            fullWidth
                        />
                        <TextInput
                            source="priceName"
                            label="Price name"
                            variant="outlined"
                            onChange={(e) => setCurrentStripePriceName(e.target.value)}
                            fullWidth
                        />
                        <BooleanInput
                            source="isOffered"
                            label="Offered ?"
                            variant="outlined"
                            onChange={(e) => setCurrentStripePriceIsOffered(e.target.checked)}
                        />
                        <Button
                            variant="outlined"
                            startIcon={<CheckIcon/>}
                            onClick={onSave}>
                            Save
                        </Button>
                    </SimpleForm>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Edit Stripe price?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Change stripe price ${record.priceId} = ${currentStripePriceName} ?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

