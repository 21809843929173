import React from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {FunctionField} from "react-admin";

type UserPopoverFieldProps = {
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>, currentEnvironmentId: number) => void;
    handleClose: () => void;
    anchorEl: HTMLButtonElement | null;
    currentResourceId: number | null;
    resourceIdKey: string;
    resource: string;
}

export const UserPopoverField = (props: UserPopoverFieldProps) => {
    const {
        handleOpen,
        handleClose,
        anchorEl,
        currentResourceId,
        resourceIdKey,
        resource
    } = props;

    return (
        <FunctionField label={"User"} render={(record: any) => {
            if (!record[resource]) {
                return null;
            }
            return (
                <>
                    <Button
                        onClick={(e) => handleOpen(e, record[resourceIdKey])}
                    >
                        {record[resource]?.firstName} {record[resource]?.lastName}
                    </Button>
                    <Popover
                        id={record[resourceIdKey]}
                        open={currentResourceId === record[resourceIdKey] && Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 1}}>
                            <b>
                                <a href={`User#/User?filter=${encodeURIComponent(JSON.stringify({ userId : record[resource]?.userId }))}`}>
                                    User ID : {record[resource]?.userId}
                                </a>
                            </b>
                        </Typography>
                        <Typography sx={{p: 1}}><b>First Name:</b> {record[resource]?.firstName}</Typography>
                        <Typography sx={{p: 1}}><b>Last Name:</b> {record[resource]?.lastName}</Typography>
                        <Typography sx={{p: 1}}><b>Email Address:</b> {record[resource]?.email}</Typography>
                        <Typography sx={{p: 1}}><b>Phone Number:</b> {record[resource]?.phoneNumber}</Typography>
                        <Typography sx={{p: 1}}><b>Deleted:</b> {record[resource]?.isDeleted ? '✅' : '❌'}</Typography>
                    </Popover>
                </>
            );
        }}/>
    );
}
