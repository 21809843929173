import { AutocompleteInput, AutocompleteInputProps } from "react-admin";
import { useEventTypes } from "../Lists/User/useEventTypes";

export const EventTypeSelectInput = (props: AutocompleteInputProps) => {
  const { label, source, ...rest } = props;

  const { isLoading, eventTypes } = useEventTypes();

  let choices: any[] = eventTypes?.map((eventType: any) => ({
    id: eventType.eventTypeId,
    name: eventType.name,
  }));
  choices.push({ id: 0, name: "[None]" });

  return (
    <AutocompleteInput
      label={label}
      source={source}
      choices={choices}
      isLoading={isLoading}
      isFetching={isLoading}
      {...rest}
    />
  );
};
