
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import { useRedirect } from "react-admin";

export type EditEventActionProps = {
  record: any;
};

export const EditUser = ({ record }: EditEventActionProps) => {
  const redirect = useRedirect();

  const handleClick = () => {
    redirect("edit", "User", record.userId);
  };

  return (
    <>
      <Tooltip title="Edit User">
        {
          <IconButton onClick={handleClick}>
            <EditIcon />
          </IconButton>
        }
      </Tooltip>
    </>
  );
};
