import {
  Datagrid,
  DateInput,
  FunctionField,
  List,
  Pagination,
  TextField,
  TextInput,
} from "react-admin";
import { isNumber } from "../../../utils";
import { TimestampDateField } from "../../Fields";
import { ImageURLField } from "../../Fields/ImageURLField";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { B2BRequest } from "./types";
import { useB2BRequestStatuses } from "./useB2BRequestStatus";

const b2BRequestFilters = [
  <TextInput
    label="API request ID"
    source="b2BRequestId"
    validate={[
      (value: string) =>
        value === null || isNumber(value) ? null : "Must be a number",
    ]}
    resettable
    alwaysOn
  />,
  <TextInput
    label="API user ID"
    source="b2BUserId"
    validate={[
      (value: string) =>
        value === null || isNumber(value) ? null : "Must be a number",
    ]}
    resettable
  />,
  <DateInput
    label={"From Date"}
    source="StartDate"
    parse={(date) => String(new Date(date).getTime() / 1000)}
  />,
  <DateInput
    label={"To Date"}
    source={"EndDate"}
    parse={(date) => String(new Date(date).getTime() / 1000)}
  />,
];

export const B2BRequestList = () => {
  const { getB2BRequestStatus } = useB2BRequestStatuses();

  return (
    <List
      title="API requests"
      filterDefaultValues={{ b2BUserId: null, b2BRequestId: null }}
      queryOptions={{
        meta: {
          alternativeId: "b2BRequestId",
          pagination: true,
          dataExtractFunction: (data: {
            payload: { results: B2BRequest[] };
          }) => {
            return data.payload.results;
          },
          overridePath: "B2BRequests",
        },
      }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
      filters={b2BRequestFilters}
    >
      <ListFilterCleaner>
        <Datagrid bulkActionButtons={false}>
          <TextField sortable={false} label="ID" source="b2BRequestId" />
          <TextField sortable={false} label="API user ID" source="b2BUserId" />
          <TimestampDateField
            sortable={false}
            label="Date"
            source="timestamp"
          />
          <FunctionField
            label="Status"
            render={(record: B2BRequest) => {
              return getB2BRequestStatus(record.status);
            }}
          />
          <FunctionField
            label="Input image"
            render={(record: B2BRequest) => {
              return <ImageURLField imageURL={record.sourcePath} />;
            }}
          />
          <FunctionField
            label="Parameters"
            render={(record: B2BRequest) => {
              try {
                return JSON.stringify(JSON.parse(record.params), null, 4);
              } catch (error) {
                return record.params;
              }
            }}
          />
          <FunctionField
            label="Output image"
            render={(record: B2BRequest) => {
              return <ImageURLField imageURL={record.destPath} />;
            }}
          />
        </Datagrid>
      </ListFilterCleaner>
    </List>
  );
};
