import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  NumberField,
  Pagination,
  TextField,
  TextInput,
} from "react-admin";
import { isNumber } from "../../../utils";
import { unixTimestampToString } from "../../../utils/date";
import { UserProfileImageField } from "../../Fields/UserProfileImageField";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { ActionsField } from "./ActionsField";
import { User } from "./types";
import { useCountryTypes } from "./useCountryTypes";
import { useImageStyles } from "./useImageStyles";
import { useUserTypes } from "./useUserTypes";
import { useEventTypes } from "./useEventTypes";

const userFilters = [
  <TextInput label="First Name" source="firstName" resettable alwaysOn />,
  <TextInput label="Last Name" source="lastName" resettable alwaysOn />,
  <TextInput label="Phone number" source="phoneNumber" resettable />,
  <TextInput label="Email" source="email" resettable />,
  <TextInput
    label="User ID"
    source="userId"
    validate={[
      (value: string) =>
        value === null || isNumber(value) ? null : "Must be a number",
    ]}
    resettable
  />,
];

export const UserList = () => {
  const { getUserType } = useUserTypes();
  const { getCountryName } = useCountryTypes();
  const { getImageStyle } = useImageStyles();
  const { getEventType } = useEventTypes();

  return (
    <List
      title="Users"
      filterDefaultValues={{ userId: null }}
      queryOptions={{
        meta: {
          alternativeId: "userId",
          pagination: true,
          dataExtractFunction: (data: { payload: { results: User[] } }) => {
            return data.payload.results;
          },
        },
        refetchOnMount: false,
      }}
      filter={{ IncludeDeleted: true }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
      sort={{ field: "userId", order: "DESC" }}
      filters={userFilters}
    >
      <ListFilterCleaner>
        <Datagrid bulkActionButtons={false}>
          <TextField sortable label="ID" source="userId" />
          <UserProfileImageField label="Profile Image" source="firstName" />
          <FunctionField
            sortable={false}
            label={"Name"}
            source="countryId"
            render={(record: User) => record.firstName + " " + record.lastName}
          />
          <FunctionField
            sortable={false}
            label="Type"
            source="userType"
            render={(record: User) => getUserType(record.userType)}
          />
          <FunctionField
            label="Created"
            render={(record: User) => {
              return unixTimestampToString(record.createdDate);
            }}
          />
          <FunctionField
            sortable={false}
            label={"Country"}
            source="countryId"
            render={(record: User) => getCountryName(record.countryId)}
          />
          <TextField sortable={false} label="Phone" source="phoneNumber" />
          <TextField sortable={false} source="email" />
          <FunctionField
            sortable={false}
            label="Default style"
            source="style"
            render={(record: User) => getImageStyle(record.style)}
          />
          <BooleanField
            sortable={false}
            label="Default auto enhance"
            source="autoEnhance"
          />
          <BooleanField sortable={false} label="Deleted?" source="isDeleted" />
          <BooleanField sortable={false} label="Blocked?" source="isBlocked" />
          <BooleanField sortable={false} label="Email verified?" source="isEmailVerified" />
          <BooleanField
            sortable={false}
            source="allowWGUpload"
            label="Allow upload?"
          />
          <BooleanField
            sortable={false}
            source="enableSubscriptions"
            label="Subscriptions?"
          />
          <TextField sortable={false} source="company"/>
          <TextField sortable={false} source="jpegOutputQuality"/>
          <FunctionField
            sortable={false}
            label="Default event type"
            source="defaultEventTypeId"
            render={(record: User) => record.defaultEventTypeId ? getEventType(record.defaultEventTypeId) : ""}
          />
          <ActionsField />
        </Datagrid>
      </ListFilterCleaner>
    </List>
  );
};
