import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

export type LightroomPresetStatus = {
    id: number,
    name: string
};

export const useLightroomPresetStatuses = () => {

    const [lightroomPresetStatuses, setLightroomPresetStatuses] = useState<LightroomPresetStatus[]>([]);

    const getLightroomPresetStatus = (lightroomPresetStatus: number) => {
        return lightroomPresetStatuses.find(u => u.id === lightroomPresetStatus)?.name;
    }

    const getLightroomPresetStatusNames = () => {
        return lightroomPresetStatuses.map(u => u.name);
    }

    const getLightroomPresetStatusId = (name: string) => {
        return lightroomPresetStatuses.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'LightroomPresets/Statuses',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setLightroomPresetStatuses(data);
        }
    }, [data, isLoading]);

    return {
        lightroomPresetStatuses: lightroomPresetStatuses,
        getLightroomPresetStatus: getLightroomPresetStatus,
        getLightroomPresetStatusNames: getLightroomPresetStatusNames,
        isLoading: isLoading,
        getLightroomPresetStatusId: getLightroomPresetStatusId,
    }
}