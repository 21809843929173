import {useListContext, useNotify, useResetStore} from "react-admin";
import React, {useEffect} from "react";
import CircularProgress from "@mui/material/CircularProgress";

type ListFilterCleanerProps = {
    children: JSX.Element;
}

export const ListFilterCleaner = (props: ListFilterCleanerProps) => {
    const {children} = props;
    const { total, setFilters } = useListContext();
    const resetStore = useResetStore();
    const notify = useNotify();

    useEffect(() => {
        if (total === -1) {
            notify("Error loading data", {type: "warning"});
        }
    }, [resetStore, setFilters, total, notify]);


    if (total === -1) {
        return <CircularProgress/>;
    }

    return children;
}
