import {FunctionField} from "react-admin";
import Box from "@mui/material/Box";
import {UnBlockUser} from "./UnBlockUser";
import {BlockUser} from "./BlockUser";
import {DeleteUser} from "./DeleteUser";
import {EditUser} from "./EditUserAction";
import {SendOTPToUser} from "./SendOTPToUser";
import {ChangeUserPhone} from "./ChangeUserPhone";
import {WifiConfig} from "./WifiConfig";
import { SubscriptionStatusDialog } from "./SubscriptionStatusDialog";
import { User } from "./types";

export const ActionsField = () => {
    return (
        <FunctionField
            label={"Actions"}
            render={
                (record: User) => {
                    return (
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            {
                                record.isDeleted ? null : (
                                    <>
                                        <EditUser record={record}/>
                                        {
                                            record.isBlocked ?
                                                <UnBlockUser record={record}/> :
                                                <BlockUser record={record}/>
                                        }
                                        <SubscriptionStatusDialog record={record}/>
                                        <ChangeUserPhone record={record}/>
                                        <SendOTPToUser record={record}/>
                                        <DeleteUser record={record}/>
                                        <WifiConfig record={record} />
                                    </>
                                )
                            }
                        </Box>
                    );
                }
            }
        />
    );
};
