import {FunctionField} from "react-admin";
import {DeleteStripePriceAction} from "./DeleteStripePriceAction";
import { EditStripePriceAction } from "./EditStripePriceAction";

export const StripePricesActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: any) => {
                    return (
                        <>
                            <DeleteStripePriceAction record={record}/>
                            <EditStripePriceAction record={record}/>
                        </>
                    );
                }
            }
        />
    );
};
