import React, {useState} from "react";

import {
    Datagrid,
    FunctionField,
    List,
    TextField,
    TextInput,
    WrapperField
} from "react-admin";
import {TimestampDateField} from "../../../Fields";
import {UserPopoverField} from "./UserPopoverField";
import {AssignUser} from "./AssignUserAction";
import {ListFilterCleaner} from "../../../ListFilterCleaner/ListFilterCleaner";
import {isNumber} from "../../../../utils";

const equipmentFilters = [
    <TextInput
        label="Serial"
        source="Serial"
        resettable
    />,
    <TextInput
        label="Esn"
        source="Esn"
        resettable
    />,
    <TextInput
        label="UserId"
        source="UserId"
        validate={
            [
                (value: string) => isNumber(value) ? undefined : "Must be a number",
            ]
        }
        resettable
    />,
    <TextInput
        label="EquipmentId"
        source="EquipmentId"
        validate={
            [
                (value: string) => isNumber(value) ? undefined : "Must be a number",
            ]
        }
        resettable
    />,
];

export const BackOfficeEquipmentList = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentEquipmentId, setCurrentEquipmentId] = useState<number | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, currentEquipmentId: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentEquipmentId(currentEquipmentId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentEquipmentId(null);
    };

    return (
        <List
            title="Equipments"
            queryOptions={{
                meta: {
                    alternativeId: 'equipmentId',
                    pagination: true,
                    dataExtractFunction: (data: { payload: any }) => {
                        return data.payload.results;
                    }
                },
                refetchOnMount: false,
            }}
            filter={{'OnlyUnassigned': false}}
            perPage={25}
            filters={equipmentFilters}
        >
            <ListFilterCleaner>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="equipmentId"/>
                    <TextField source="serial"/>
                    <TextField source="esn"/>
                    <TimestampDateField source="created"/>
                    <WrapperField source={'user'}>
                        <UserPopoverField
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                            currentEquipmentId={currentEquipmentId}
                        />
                        <FunctionField label={"User"} render={(record: any) => (
                            <AssignUser record={record}/>
                        )}/>
                    </WrapperField>
                </Datagrid>
            </ListFilterCleaner>
        </List>
    );
};
