import React from "react";
import {Create, SimpleForm, TextInput, useNotify, useRedirect, required} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";

export const EventTypeCreate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {
        mutateAsync: addEventType,
        isLoading: isLoadingAddEventType
    } = useMutation(({name}: { name: string }) => {
        return dataProvider.saPost(`EventType?name=${name}`);
    }, {
        onSuccess: async (data) => {
            notify("Event Type added successfully", {type: 'success'});
            redirect('list', 'EventType');
        },
        onError: (data) => {
            notify("Event Type addition failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`EventType`);
        }
    });

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Add Event Type
            </Typography>
            <SimpleBackdrop open={isLoadingAddEventType}/>
            <Create>
                <SimpleForm onSubmit={async (formValues) => {
                    if (!formValues.name) {
                        notify("Name is required", {type: 'error'});
                        return;
                    }
                    await addEventType({
                        name: formValues.name,
                    });
                }}>
                    <TextInput source="name" validate={[required()]} fullWidth/>
                </SimpleForm>
            </Create>
        </>
    );
};
