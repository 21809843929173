import Typography from "@mui/material/Typography";
import { useDataProvider } from "ra-core";
import { Create, useNotify, useRedirect } from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import SimpleBackdrop from "../../SimpleBackdrop";
import { UpsertB2BUserForm } from "./UpsertB2BUserForm";
import { B2BUserCreateInput } from "./types";

export const B2BUserCreate = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const { mutateAsync: addB2BUser, isLoading: isLoadingAddUser } = useMutation(
    (input: B2BUserCreateInput) => {
      return dataProvider.saPut(
        `B2BUsers?Name=${encodeURIComponent(input.name)}` +
          `&Email=${encodeURIComponent(input.email)}` +
          `&Phone=${encodeURIComponent(input.phone)}` +
          `&IsBlocked=${input.isBlocked}` +
          `&BlobConnection=${encodeURIComponent(input.blobConnection)}` +
          `&BlobStorageBaseURL=${encodeURIComponent(input.blobStorageBaseURL)}` +
          `&UseWebhook=${input.useWebhook}` +
          `&WebhookUrl=${encodeURIComponent(input.webhookUrl)}` +
          `&WebhookAuthenticationType=${input.webhookAuthenticationType}` +
          `&WebhookCredentials=${encodeURIComponent(input.webhookCredentials)}` +
          `&AlgoEndpoint=${encodeURIComponent(input.algoEndpoint)}` +
          `&AlgoEndpointUsername=${encodeURIComponent(
            input.algoEndpointUsername
          )}` +
          `&AlgoEndpointPassword=${encodeURIComponent(
            input.algoEndpointPassword
          )}`
      );
    },
    {
      onSuccess: async (data: { data: { code: number; message: string } }) => {
        if (data.data.code > 0) {
          notify(`API User creation failed - ${data.data.message}`, {
            type: "error",
          });
        } else {
          notify("API User created successfully", { type: "success" });
          redirect("list", "B2BUser");
        }
      },
      onError: (data) => {
        notify("User creation failed", { type: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries(`User`);
      },
    }
  );

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ marginTop: 5 }}>
        Add New API User
      </Typography>
      <SimpleBackdrop open={isLoadingAddUser} />
      <Create>
        <UpsertB2BUserForm
          onSubmit={(e) => addB2BUser(e as B2BUserCreateInput)}
          isUpdate={false}
        />
      </Create>
    </>
  );
};
