import moment from "moment/moment";

export const getFullName = (user: any) => `${user.firstName} ${user.lastName}`;

export const isValidHttpUrl = (s: string) => {
    let url;

    try {
        url = new URL(s);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}


export const isNumber = (n: any) => !isNaN(parseFloat(n)) && !isNaN(n - 0);

export const dateToTimestamp = (date: Date): number => {
    return moment(date).unix();
}

export const timestampToDate = (timestamp: number | string): Date => {
    if (typeof timestamp !== "number") {
        return new Date();
    }
    return new Date(timestamp * 1000);
}

export const timestampToDateString = (timestamp: number | string, format: string = "yyyy-MM-DDTHH:mm:ss.SSS"): string => {
    if (typeof timestamp !== "number") {
        return "";
    }
    return moment.unix(timestamp).format(format);
}
