import {AutocompleteInput, AutocompleteInputProps} from "react-admin";
import { useUserTypes } from '../Lists/User/useUserTypes';

export const UserTypeSelectInput = (props: AutocompleteInputProps) => {
    const {userTypes, isLoading} = useUserTypes();

    return (
        <AutocompleteInput
            label={props.label}
            source={props.source}
            choices={userTypes}
            isLoading={isLoading}
            isFetching={isLoading}
        />
    );
}
