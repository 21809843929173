import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { isNumber } from "../../../utils";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { ActionsField } from "./ActionsField";
import { EventTypeType } from "./types";

const eventTypeFilters = [
  <TextInput
    label="Event Type Id"
    source="EventTypeId"
    validate={[
      (value: string) => (isNumber(value) ? undefined : "Must be a number"),
    ]}
    resettable
  />,

  <TextInput label="Event Type Name" source="Name" resettable />,
];

export const EventTypeList = () => (
  <List
    title="Event Types"
    queryOptions={{
      meta: {
        alternativeId: "eventTypeId",
        pagination: false,
        dataExtractFunction: (data: { payload: EventTypeType[] }) => {
          return data.payload;
        },
      },
      refetchOnMount: true,
    }}
    perPage={25}
    filters={eventTypeFilters}
  >
    <ListFilterCleaner>
      <Datagrid bulkActionButtons={false}>
        <TextField source="eventTypeId" />
        <TextField source="name" />
        <BooleanField source="advanced" />
        <ActionsField />
      </Datagrid>
    </ListFilterCleaner>
  </List>
);
