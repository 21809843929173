import { useState } from "react";
import {
  Datagrid,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  Pagination,
  TextField,
  WrapperField,
} from "react-admin";
import { unixTimestampToString } from "../../../utils/date";
import { UserSelectInput } from "../../Inputs";
import { AccountingActionTypeSelectInput } from "../../Inputs/AccountingActionTypeSelectInput";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { UserPopoverField } from "../../Popover";
import { useAccountingActionTypes } from "../User/useAccountingActionTypes";
import { useAccountingObjectTypes } from "../User/useAccountingObjectTypes";
import { AccountingTrackType } from "./types";

const accountingTrackFilters = [
  <UserSelectInput label="User" source="UserId" alwaysOn />,
  <AccountingActionTypeSelectInput
    label="Action type"
    source="ActionTypeId"
    alwaysOn
  />,
  <NumberInput label="Event ID" source="EventId" alwaysOn />,
  <NumberInput label="Image ID" source="ImageId" alwaysOn />,
];

export const AccountingTracksList = () => {
  const { getAccountingActionType } = useAccountingActionTypes();
  const { getAccountingObjectType } = useAccountingObjectTypes();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentAccountingTrackId, setCurrentAccountingTrackId] = useState<
    number | null
  >(null);

  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    currentAccountingTrackId: number
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentAccountingTrackId(currentAccountingTrackId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentAccountingTrackId(null);
  };

  return (
    <>
      <List
        title="AccountingTracks"
        queryOptions={{
          meta: {
            pagination: true,
            dataExtractFunction: (data: {
              payload: { results: AccountingTrackType[] };
            }) => {
              return data.payload.results;
            },
            overridePath: "Accounting/Tracks",
          },
          refetchOnMount: true,
        }}
        perPage={25}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]} />}
        filters={accountingTrackFilters}
      >
        <ListFilterCleaner>
          <Datagrid bulkActionButtons={false}>
            <NumberField
              sortable={false}
              label="ID"
              source="accountingTrackId"
            />
            <WrapperField source={"user"}>
              <UserPopoverField
                handleOpen={handleOpen}
                handleClose={handleClose}
                anchorEl={anchorEl}
                currentResourceId={currentAccountingTrackId}
                resourceIdKey={"eventId"}
                resource={"user"}
              />
            </WrapperField>
            <FunctionField
              label="Object type"
              render={(record: AccountingTrackType) => {
                return getAccountingObjectType(record.objectTypeId);
              }}
            />
            <TextField
              label="Image ID"
              sortable={false}
              source="objectId"
            />
            <FunctionField
              label="Action"
              render={(record: AccountingTrackType) => {
                return getAccountingActionType(record.actionTypeId);
              }}
            />
            <FunctionField
              label="Timestamp"
              render={(record: AccountingTrackType) => {
                return unixTimestampToString(record.timestamp);
              }}
            />
            <TextField sortable={false} source="info" />
          </Datagrid>
        </ListFilterCleaner>
      </List>
    </>
  );
};
