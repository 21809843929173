import React, {useEffect, useRef, useState} from "react";

import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
    ClickAwayListener,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    NativeSelect,
    Popper,
    Tooltip
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import {useQuery, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import {useNotify} from "react-admin";
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import {timestampToDateString} from "../../../utils";
import {ProgressIndicator} from "../../ProgressIndicator/ProgressIndicator";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export type HeartbeatPopoverFieldProps = {
    record: any;
}

const ERROR_MESSAGE = 'Error fetching heartbeat';

export const HeartbeatPopoverField = ({record}: HeartbeatPopoverFieldProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [popperOpen, setPopperOpen] = useState(false);
    const [currentPopperId, setCurrentPopperId] = useState<string | null>(null);
    const [refreshProgress, setRefreshProgress] = useState<number>(0);
    const [refreshEvery, setRefreshEvery] = useState<number>(1);
    const [timerEnabled, setTimerEnabled] = useState<boolean>(false);
    const timer = useRef<any>(undefined);

    const eventId = record.eventId;

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const {data, isLoading, isError, refetch} = useQuery(["event", 'heartbeat', eventId], () => {
        return dataProvider.saGet(`Event/${eventId}/Heartbeat`, {});
    }, {
        enabled: false,
        onSuccess: (data: { data: { code: number, message: string, payload: any } }) => {
            if (data.data.code > 0) {
                notify(`Heartbeat fetch failed - ${data.data.message}`, {type: 'error'});
            }
        },
        onError: () => {
            notify(ERROR_MESSAGE, {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(["event", 'heartbeat', eventId]);
        }
    });

    useEffect(() => {
        if (!timerEnabled) {
            clearInterval(timer.current)
            return;
        }
        timer.current = setInterval(() => {
            if (refreshProgress >= refreshEvery) {
                setRefreshProgress(0);
                refetch();
            }
            setRefreshProgress((prev) => prev + 1);
        }, 1000);
        return () => {
            clearInterval(timer.current)
        }
    }, [refetch, timerEnabled, refreshProgress, refreshEvery]);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        refetch();
        setTimerEnabled(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimerEnabled(false);
    };

    const handlePopperOpen = (event: React.MouseEvent<HTMLButtonElement>, popperId: string) => {
        if (popperOpen) {
            setPopperOpen(false);
        }
        setCurrentPopperId(popperId);
        setPopperAnchorEl(event.currentTarget);
        setPopperOpen(prev => !prev);
    };

    const copySerial = (value: string) => {
        navigator.clipboard.writeText(value);
        notify(`Serial copied!`, { type: 'success' });
    }

    return (
        <>
            <Tooltip title={`Heartbeart`}>
                <IconButton onClick={handleOpen}>
                    <NetworkCheckIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={record.eventId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    zIndex: 1,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        minHeight: 200,
                        minWidth: 400,
                        maxWidth: 1200,
                        maxHeight: 600,
                        paddingY: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'auto'
                    }}
                >
                    {
                        isLoading ?
                            <CircularProgress/> : isError || !data?.data?.payload || !data?.data?.payload.length ?
                                <Typography variant="h4" sx={{p: 1}}>{ERROR_MESSAGE}</Typography> : (
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                        <Box sx={{
                                            p: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'end',
                                        }}>
                                            <FormControl>
                                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                    Refresh Every
                                                </InputLabel>
                                                <NativeSelect
                                                    defaultValue={60}
                                                    value={refreshEvery}
                                                    onChange={(e) => {
                                                        setRefreshEvery(parseInt(e.target.value));
                                                        setRefreshProgress(0);
                                                    }}
                                                >
                                                    <option value={1}>1 Second</option>
                                                    <option value={5}>5 Seconds</option>
                                                    <option value={30}>30 Seconds</option>
                                                    <option value={60}>1 Minute</option>
                                                </NativeSelect>
                                            </FormControl>
                                            <Box sx={{width: '20%'}}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(100 / refreshEvery) * refreshProgress}
                                                    color={"primary"}
                                                />
                                            </Box>
                                        </Box>
                                        <>
                                            {
                                                data?.data?.payload?.map((heartbeat: any) => (
                                                    <React.Fragment key={`hb-${heartbeat.userId}`}>
                                                        <Box sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'start',
                                                        }}>
                                                            <Typography variant="h5" sx={{p: 1}}>
                                                                <u>
                                                                    <b>Photographer - {heartbeat?.userId}</b>
                                                                </u>
                                                            </Typography>
                                                            <Typography variant="h6" sx={{fontSize: 14}}>
                                                                ({heartbeat?.firstName} {heartbeat?.lastName})
                                                            </Typography>
                                                            <IconButton
                                                                onClick={(e) => handlePopperOpen(e, `${eventId}-${heartbeat.userId}`)}
                                                            >
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Box>
                                                        <Popper
                                                            id={`${eventId}-${heartbeat.userId}`}
                                                            open={popperOpen && currentPopperId === `${eventId}-${heartbeat.userId}`}
                                                            anchorEl={popperAnchorEl}
                                                            style={{zIndex: 10000}}
                                                        >
                                                            <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                                                                <Box sx={{
                                                                    background: '#fff',
                                                                    borderRadius: '10px',
                                                                    border: 'solid #000 1px',
                                                                    boxShadow: 7,
                                                                }}>
                                                                    <Typography sx={{paddingX: 2, margin: 0, paddingY: 1}}>
                                                                        <b>User Id:</b> {heartbeat?.userId} <br/>
                                                                        <b>First Name:</b> {heartbeat?.firstName} <br/>
                                                                        <b>Last Name:</b> {heartbeat?.lastName} <br/>
                                                                        <b>Phone Number:</b> {heartbeat?.phoneNumber} <br/>
                                                                        <b>Email Address:</b> {heartbeat?.email} <br/>
                                                                    </Typography>
                                                                </Box>
                                                            </ClickAwayListener>
                                                        </Popper>
                                                        {
                                                            heartbeat?.boxes && heartbeat?.boxes?.length && (
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{minWidth: 900}} size="small"
                                                                           aria-label="a dense table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Box Id</TableCell>
                                                                                <TableCell>ESN</TableCell>
                                                                                <TableCell align="left">Serial</TableCell>
                                                                                <TableCell align="left">Last
                                                                                    Heartbeat</TableCell>
                                                                                <TableCell align="left">Signal Level</TableCell>
                                                                                <TableCell align="left">Battery
                                                                                    Level</TableCell>
                                                                                <TableCell align="left">Photos
                                                                                    Waiting</TableCell>
                                                                                <TableCell align="left">Photos
                                                                                    Uploaded</TableCell>
                                                                                <TableCell align="left">Photos
                                                                                    Edited</TableCell>
                                                                                <TableCell align="left">Box
                                                                                    Temperatures</TableCell>
                                                                                <TableCell align="left">Box Carrier</TableCell>
                                                                                <TableCell align="left">SD Card Queue</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {heartbeat?.boxes?.map((box: any) => (
                                                                                <TableRow
                                                                                    key={box.id}
                                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                                                >
                                                                                    <TableCell
                                                                                        component="th"
                                                                                        scope="row"
                                                                                    >
                                                                                        {box.id}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Tooltip title={box.esn}>
                                                                                            <span>{box.esn?.slice(0, 5)}</span>
                                                                                        </Tooltip>
                                                                                        <IconButton onClick={() => copySerial(box.esn)}>
                                                                                            <ContentCopyIcon fontSize={'small'}/>
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Tooltip title={box.serial}>
                                                                                            <span>{box.serial?.slice(0, 5)}</span>
                                                                                        </Tooltip>
                                                                                        <IconButton onClick={() => copySerial(box.serial)}>
                                                                                            <ContentCopyIcon fontSize={'small'}/>
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {timestampToDateString(box.lastHeartBeat, "yyyy-MM-DD HH:mm:ss") ?? ''}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <ProgressIndicator
                                                                                            value={box.signalLevel}/>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <ProgressIndicator
                                                                                            value={box.batteryLevel}/>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {box.waiting}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {box.uploaded}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {box.edited}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {box.temperatures ? `${box.temperatures}°` : 'N/A'}</TableCell>
                                                                                    <TableCell align="center">
                                                                                        {box.carrier ?? 'N/A'}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {box.sdQueue ?? 'N/A'}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                ))
                                            }
                                        </>
                                    </Box>
                                )
                    }
                </Box>
            </Popover>
        </>
    );
}

