import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";
import { EventTypeType } from "../EventType/types";

export const useEventTypes = () => {

    const [eventTypes, setEventTypes] = useState<EventTypeType[]>([]);

    const getEventType = (eventTypeID: number) => {
        const eventType : EventTypeType | undefined = eventTypes.find(u => u.eventTypeId === eventTypeID);
        return eventType?.name;
    }

    const getEventTypeNames = () => {
        return eventTypes.map(u => u.name);
    }

    const getEventTypeId = (name: string) => {
        return eventTypes.find(u => u.name === name)?.eventTypeId;
    }

    const dataExtractFunction = useCallback((data: { payload: EventTypeType[] }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'EventType',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setEventTypes(data);
        }
    }, [data, isLoading]);

    return {
        eventTypes: eventTypes,
        getEventType: getEventType,
        getEventTypeNames: getEventTypeNames,
        isLoading: isLoading,
        getEventTypeId: getEventTypeId,
    }
}