import {
    BooleanField,
    Datagrid, ExportButton, FunctionField,
    List,
    TextField, TopToolbar, WrapperField,
} from "react-admin";
import {UpdateEventUserTypeAction} from "./UpdateEventUserTypeAction";
import {RemoveEventUserAction} from "./RemoveEventUserAction";
import {AddEventUserAction} from "./AddEventUserAction";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import {UserProfileImageField} from "../../Fields/UserProfileImageField";

interface EventUserListProps {
    eventId: number;
}

export const EventUserList = (props: EventUserListProps) => {
    const {
        eventId
    } = props;

    return (
        <List
            title="EventsUsers"
            queryOptions={{
                meta: {
                    overridePath: `Event/${eventId}/Users`,
                    alternativeId: 'userId',
                    pagination: true,
                    dataExtractFunction: (data: { payload: any }) => {
                        return data.payload.results;
                    }
                },
                refetchOnMount: false,
            }}
            perPage={25}
            actions={
                <TopToolbar>
                    <AddEventUserAction eventId={eventId}/>
                    <ExportButton/>
                </TopToolbar>
            }
        >
            <ListFilterCleaner>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="userId"/>
                    <UserProfileImageField label="Profile Image"/>
                    <TextField source="firstName"/>
                    <TextField source="lastName"/>
                    <TextField source="email"/>
                    <TextField label="Phone number" source="phoneNumber"/>
                    <BooleanField label="deleted?" source="isDeleted"/>
                    <BooleanField label="Only see tagged photos?" source="onlySeeTaggedPhotos"/>
                    <WrapperField source={'eventUserType.name'}>
                        <TextField source="eventUserType.name"/>
                        <FunctionField label={"User Type"} render={(record: any) => (
                            <UpdateEventUserTypeAction record={record} eventId={eventId}/>
                        )}/>
                    </WrapperField>
                    <TextField source="approvalStatus.name"/>
                    <FunctionField label={"Remove"} render={(record: any) => (
                        <RemoveEventUserAction record={record} eventId={eventId}/>
                    )}/>
                </Datagrid>
            </ListFilterCleaner>
        </List>
    );
}
