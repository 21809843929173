import {useRecordContext} from "ra-core";
import {useState} from "react";
import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import {useImageConfig} from "../../context/ImageConfigContext";
import { useDataProvider } from "react-admin";

export const UserProfileImageField = (props: any) => {
    const record = useRecordContext(props);
    const { imageConfig } = useImageConfig();
    const dataProvider = useDataProvider();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = async () => {
        if (record?.userProfileImageValid === undefined) {
            const response = await dataProvider.saGet(`UserImage/ProfileImage/${record.id}`, {});
            record.userProfileImageValid = response.data.payload.isUsableForFaceRec;
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const image = record?.profileImage ? imageConfig.userImagesBase + record?.profileImage : '/user.png'
    return (
        <>
            <img
                src={image}
                alt={record.name}
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    border: '3px solid #ccc',
                    borderColor: `${record?.userProfileImageValid === undefined ? "grey" :
                        record?.userProfileImageValid ? "lightgreen" : "orangered"}`
                }}
                onMouseOver={record?.profileImage && openModal}
                onMouseOut={record?.profileImage && closeModal}
            />

            <Modal
                open={!!modalIsOpen}
                onClose={closeModal}
                aria-labelledby="parent-modal-title"
                style={{
                    pointerEvents: 'none',
                }}
            >
                <Box justifyContent="center" alignItems="center" display="flex" height="100vh">
                    <img
                        src={image} // Display the original image here
                        alt={record.name}
                        style={{
                            height: '90vh',
                            objectFit: 'contain',
                            border: '3px solid #ccc',
                            borderColor:`${record?.userProfileImageValid === undefined ? "grey" :
                                record?.userProfileImageValid ? "lightgreen" : "orangered"}`
                        }}
                    />
                    <h1
                        style={{
                            color:`${record?.userProfileImageValid === undefined ? "grey" :
                                record?.userProfileImageValid ? "lightgreen" : "orangered"}`,
                            padding: '5px'
                        }}
                    >Face recognition {record?.userProfileImageValid === undefined ? "..." :
                        record?.userProfileImageValid ? "valid" : "invalid"}
                    </h1>
                </Box>
            </Modal>
        </>
    );
};
