import React from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {FunctionField} from "react-admin";
import { useLightroomPresetStatuses } from "../Lists/User/useLightroomPresetStatuses";
import { LightroomPreset } from "../Lists/LightroomPreset/types";
import { unixTimestampToString } from "../../utils/date";

type LightroomPresetPopoverFieldProps = {
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>, currentEnvironmentId: number) => void;
    handleClose: () => void;
    anchorEl: HTMLButtonElement | null;
    currentResourceId: number | null;
    resourceIdKey: string;
    resource: string;
}

export const LightroomPresetPopoverField = (props: LightroomPresetPopoverFieldProps) => {

    const {
        handleOpen,
        handleClose,
        anchorEl,
        currentResourceId,
        resourceIdKey,
        resource
    } = props;

    const { getLightroomPresetStatus } = useLightroomPresetStatuses();

    return (
        <FunctionField label={"LightroomPreset"} render={(record: any) => {

            let lightroomPreset: LightroomPreset = record[resource];

            if (!lightroomPreset) {
                return null;
            }

            return (
                <>
                    <Button
                        onClick={(e) => handleOpen(e, record[resourceIdKey])}
                    >
                        {lightroomPreset.name}
                    </Button>
                    <Popover
                        id={record[resourceIdKey]}
                        open={currentResourceId === record[resourceIdKey] && Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 1}}><b>LightroomPreset {lightroomPreset.lightroomPresetId}</b></Typography>
                        <Typography sx={{p: 1}}><b>Name:</b> {lightroomPreset.name}</Typography>
                        {
                            lightroomPreset.userId &&
                            <Typography sx={{p: 1}}>
                                <b>
                                    <a href={`User#/User?filter=${encodeURIComponent(JSON.stringify({ userId : lightroomPreset.userId }))}`}>
                                        User ID : {lightroomPreset.userId}
                                    </a>
                                </b>
                            </Typography>
                        }
                        <Typography sx={{p: 1}}><b>Created:</b> {unixTimestampToString(lightroomPreset.created)}</Typography>
                        <Typography sx={{p: 1}}><b>Last modified:</b> {unixTimestampToString(lightroomPreset.modified)}</Typography>
                        <Typography sx={{p: 1}}><b>Size:</b> {lightroomPreset.presetSize} bytes</Typography>
                        <Typography sx={{p: 1}}><b>Status:</b> {getLightroomPresetStatus(lightroomPreset.status)}</Typography>
                        <Typography sx={{p: 1}}><b>Global:</b> {lightroomPreset.isGlobal ? '✅' : '❌'}</Typography>
                        <Typography sx={{p: 1}}><b>Deleted:</b> {lightroomPreset.isDeleted ? '✅' : '❌'}</Typography>
                    </Popover>
                </>
            );
        }}/>
    );
}
