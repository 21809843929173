import React, {useEffect} from "react";

import {isValidHttpUrl, loginRequest, msalInstance} from "../utils";

export const LoginPage = () => {

    useEffect(() => {
        const referrer = document.referrer;
         if (referrer && referrer !== '' && isValidHttpUrl(referrer)) {
             const { hostname } = new URL(referrer);
             if (hostname && hostname.includes('snapify.ai')) {
                 msalInstance.loginRedirect(loginRequest);
             }
         }
    }, []);

    return (
        <div>
            <h1>Snapify Back Office</h1>
            <button onClick={() => msalInstance.loginRedirect(loginRequest)}>Login</button>
        </div>
    );
}
