import { useGetList } from "react-admin";
import { useCallback, useEffect, useState } from "react";

type B2BRequestStatus = {
    id: number,
    name: string
};

export const useB2BRequestStatuses = () => {

    const [b2BRequestStatuses, setB2BRequestStatuses] = useState<B2BRequestStatus[]>([]);

    const getB2BRequestStatus = (b2BRequestStatus: number) => {
        return b2BRequestStatuses.find(u => u.id === b2BRequestStatus)?.name;
    }

    const getB2BRequestStatusNames = () => {
        return b2BRequestStatuses.map(u => u.name);
    }

    const getB2BRequestStatusId = (name: string) => {
        return b2BRequestStatuses.find(u => u.name === name)?.id;
    }

    const dataExtractFunction = useCallback((data: { payload: B2BRequestStatus[] }) => {
        return data.payload;
    }, []);

    const {data, isLoading} = useGetList(
        'B2BRequests/RequestStatus',
        {
            meta: {
                pagination: false,
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (data?.length) {
            setB2BRequestStatuses(data);
        }
    }, [data, isLoading]);

    return {
        b2BRequestStatuses: b2BRequestStatuses,
        getB2BRequestStatus: getB2BRequestStatus,
        getB2BRequestStatusNames: getB2BRequestStatusNames,
        isLoading: isLoading,
        getB2BRequestStatusId: getB2BRequestStatusId,
    }
}