import { useEffect, useMemo, useRef } from "react";
import { FaceRecTag } from "../Lists/Image/types";

interface FaceRecCanvasProps {
  imageUrl: string;
  faceRecTags: FaceRecTag[];
}

export const FaceRecCanvas = (props: FaceRecCanvasProps) => {
  const { imageUrl, faceRecTags } = props;

  const faceRecCanvas = useRef<HTMLCanvasElement>(null);

  const generateRandomColor = () =>
    `hsla(${Math.random() * 360}, 100%, 50%, 1)`;

  const colorsMap: string[] = useMemo(() => {
    let result: string[] = [];
    for (let i = 0; i < 10000; ++i) {
      result.push(generateRandomColor());
    }
    return result;
  }, []);

  useEffect(() => {
    const canvas = faceRecCanvas.current;

    if (canvas) {
      const canvasContext = canvas.getContext("2d");
      if (canvasContext) {
        canvasContext.clearRect(
          0,
          0,
          canvas.width,
          canvas.height
        );
        canvasContext.strokeText("Loading....", 0, 0);

        const img = new Image();

        img.src = imageUrl;
        img.onload = () => {
          const ratio = img.width / img.height;
          canvas.height = canvas.clientHeight;
          canvas.width = canvas.height * ratio;
          const width = canvas.width;
          const height = canvas.height;
          canvasContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
          canvasContext.lineWidth = 2;
          canvasContext.font = "16pt Courier";
          canvasContext.fillStyle = "white";
          faceRecTags.forEach((faceRecTag) => {
            canvasContext.strokeStyle = colorsMap[faceRecTag.userId];
            const rect = faceRecTag.tagData.rectangle;
            canvasContext.strokeRect(
              rect[0] * width,
              rect[1] * height,
              (rect[2] - rect[0]) * width,
              (rect[3] - rect[1]) * height
            );
            canvasContext.strokeText(
              "User " + String(faceRecTag.userId),
              rect[0] * width,
              rect[1] * height - 10
            );
          });
        };
      }
    }
  }, [faceRecCanvas, faceRecTags, imageUrl, colorsMap]);

  return (
    <canvas
      ref={faceRecCanvas} style={{ height:"100%", padding: 0}}
    />
  );
};
