import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useAppPicker} from "../../../hooks";

export const AppPicker = () => {
    const {currentApp, apps, onAppSelected} = useAppPicker();
    return (
        <FormControl>
            <InputLabel
                style={{ color: "white" }}
                id="app-picker-label">Application</InputLabel>
            <Select
                labelId="app-picker-label"
                id="app-picker-select"
                value={currentApp?.url ?? ""}
                label="Application"
                sx={{
                    '.MuiSelect-select': {
                        color: 'white'
                    },
                    '.MuiSelect-icon': {
                        color: 'white'
                    },
                    ".MuiSelect-outlined":{
                        color: 'white'
                }}}
                onChange={(e) => onAppSelected(e.target.value)}
            >
                {
                    apps.map((app) => (
                        <MenuItem key={app.url} value={app.url}>{app.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
}
