import React, {useEffect, useState} from "react";

import Popover from "@mui/material/Popover";
import {Checkbox, IconButton, Tooltip} from "@mui/material";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import {Button, useNotify} from "react-admin";
import {useDataProvider} from "ra-core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

export type SubscriptionStatusProps = {
    record: any;
}

export const SubscriptionStatusDialog = ({record}: SubscriptionStatusProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [enableSubscriptions, setEnableSubscriptions] = useState<boolean>(false);
    const [isTrial, setIsTrial] = useState<boolean>(false);
    const [trialUsage, setTrialUsage] = useState<number>(0);
    const [trialLimit, setTrialLimit] = useState<number>(0);
    const [stripeCheckoutUrl, setStripeCheckoutUrl] = useState<string | null>(null);
    const [stripeCustomerPortalUrl, setStripeCustomerPortalUrl] = useState<string | null>(null);
    const [currentUsage, setCurrentUsage] = useState<number>(0);

    const [isLoading, setLoading] = useState<boolean>(false);

    const dataProvider = useDataProvider();

    useEffect(() => {
        async function fetchUserSubscriptionStatus() {
            try {
                setLoading(true);
                const response = await dataProvider.saGet(`User/${record.id}/SubscriptionStatus`, {});
                if (response.data.code !== 0)
                {
                    notify(response.data.message, {type: 'error'});
                    setAnchorEl(null);
                    return;
                }
                var payload = response.data.payload;
                setEnableSubscriptions(payload.enableSubscriptions);
                setIsTrial(payload.isTrial);
                setTrialUsage(payload.trialUsage);
                setTrialLimit(payload.trialLimit);
                setCurrentUsage(payload.currentUsage);

                const customerPortalUrlResponse = await dataProvider.saGet(`User/${record.id}/CustomerPortalUrl`, {});
                if (customerPortalUrlResponse.data.code === 0)
                {
                    setStripeCustomerPortalUrl(customerPortalUrlResponse.data.payload);
                }
                const checkoutUrlResponse = await dataProvider.saGet(`User/${record.id}/CheckoutUrl`, {});
                if (checkoutUrlResponse.data.code === 0)
                {
                    setStripeCheckoutUrl(checkoutUrlResponse.data.payload);
                }
            } catch (e) {
                notify("Fetching the subscription status failed", {type: 'error'});
            } finally {
                setLoading(false);
            }
        }

        anchorEl && fetchUserSubscriptionStatus();

    }, [record.userId, anchorEl]);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function subscribeToTrial() {
        const response = await dataProvider.saPost(`User/${record.id}/TrialPlan`, {});;
        if (response.data.code !== 0)
        {
            notify(response.data.message, {type: 'error'});
        }
        else
        {
            notify(`${record.firstName} ${record.lastName} Subscribed to trial plan`, {type: 'success'});
        }
        handleClose();
    };

    async function subscribeToPaid() {
        const response = await dataProvider.saPost(`User/${record.id}/PaidPlan`, {});;
        if (response.data.code !== 0)
        {
            notify(response.data.message, {type: 'error'});
        }
        else
        {
            notify(`${record.firstName} ${record.lastName} Subscribed to paid plan`, {type: 'success'});
        }
        handleClose();
    };

    return (
        <>
            <Tooltip title={`Subscription status : ${record.firstName} ${record.lastName} (${record.userId})`}>
                <IconButton onClick={handleOpen}>
                    <CardMembershipIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={String(record.userId)}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <Box sx={{
                    width: 400,
                    height: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    {isLoading ? <CircularProgress/> :
                    <>
                        <Typography variant="h6">Subscription status : {record.firstName} {record.lastName} ({record.userId})</Typography>
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left'
                            }}>
                            <Typography variant="body1" sx={{p: 1}}>
                                Subscriptions enabled
                                <Checkbox disabled checked={enableSubscriptions}></Checkbox>
                            </Typography>
                            <Typography variant="body1" sx={{p: 1}}>
                                On trial plan
                                <Checkbox disabled checked={isTrial}></Checkbox>
                                {isTrial &&
                                    <Button onClick={subscribeToPaid} label="Subscribe to paid plan"></Button>
                                }
                                {!isTrial &&
                                    <Button onClick={subscribeToTrial} label="Subscribe to trial plan"></Button>
                                }
                            </Typography>
                            {isTrial &&
                                <Typography variant="body1" sx={{p: 1}}>
                                    Trial usage : {trialUsage.toString()}
                                </Typography>
                            }
                            {isTrial &&
                                <Typography variant="body1" sx={{p: 1}}>
                                    Trial limit : {trialLimit.toString()}
                                </Typography>
                            }
                            {!isTrial &&
                                <Typography variant="body1" sx={{p: 1}}>
                                    Current usage : {currentUsage.toString()}
                                </Typography>
                            }
                            {stripeCheckoutUrl &&
                                <Typography variant="body1" sx={{p: 1}}>
                                    <a target="_blank" rel="noreferrer" href={stripeCheckoutUrl}>Stripe checkout</a>
                                </Typography>
                            }
                            {stripeCustomerPortalUrl &&
                                <Typography variant="body1" sx={{p: 1}}>
                                    <a target="_blank" rel="noreferrer" href={stripeCustomerPortalUrl}>Stripe customer portal</a>
                                </Typography>
                            }
                        </Box>
                    </>}
                </Box>
            </Popover>
        </>
    );
}

