import React, {useState} from "react";

import EditOff from '@mui/icons-material/EditOff';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {useMutation, useQueryClient} from "react-query";
import {useNotify} from "react-admin";
import {useDataProvider} from "ra-core";
import { EventType } from "./types";

export type ResendNotEditedActionProps = {
    record: EventType;
}

export const ResendNotEditedAction = ({record}: ResendNotEditedActionProps) => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const {mutateAsync: ResendNotEditedEvent, isLoading: isLoadingDeleteEvent} = useMutation(() => {
        const eventId = record.eventId;
        if (!eventId) {
            return;
        }
        return dataProvider.saPost(`Event/${eventId}/ResendNotEdited`, {});
    }, {
        onSuccess: data => {
            notify("Event " + record.eventId + " resend not edited succeeded", {type: 'success'});
        },
        onError: () => {
            notify("Event " + record.eventId + " resend not edited failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Event');
        }
    });

    const handleClick = () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await ResendNotEditedEvent();
        return;
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        return;
    }

    return (
        <>
            <Tooltip title="Resend not edited">
                {
                    isLoadingDeleteEvent ? <CircularProgress/> : (
                        <IconButton onClick={handleClick}>
                            <EditOff/>
                        </IconButton>
                    )
                }
            </Tooltip>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Resend not edited on event " + record.eventId + "?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to ungroup all groups and redo Grouping ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
