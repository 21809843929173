import {
  BooleanField,
  Datagrid,
  DateInput,
  List,
  Pagination,
  TextField,
  TextInput,
} from "react-admin";
import { isNumber } from "../../../utils";
import { TimestampDateField } from "../../Fields";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { B2BRequestLog } from "./types";

const b2BRequestLogFilters = [
  <TextInput
    label="API request log ID"
    source="b2BRequestLogId"
    validate={[
      (value: string) =>
        value === null || isNumber(value) ? null : "Must be a number",
    ]}
    resettable
  />,
  <TextInput
    label="API request ID"
    source="b2BRequestId"
    validate={[
      (value: string) =>
        value === null || isNumber(value) ? null : "Must be a number",
    ]}
    alwaysOn
    resettable
  />,
  <DateInput
    label={"From Date"}
    source={"StartDate"}
    parse={(date) => String(new Date(date).getTime() / 1000)}
  />,
  <DateInput
    label={"To Date"}
    source={"EndDate"}
    parse={(date) => String(new Date(date).getTime() / 1000)}
  />,
];

export const B2BRequestLogList = () => {
  return (
    <List
      title="API requests"
      filterDefaultValues={{ b2BRequestId: null, b2BRequestLogId: null }}
      queryOptions={{
        meta: {
          alternativeId: "b2BRequestLogId",
          pagination: true,
          dataExtractFunction: (data: {
            payload: { results: B2BRequestLog[] };
          }) => {
            return data.payload.results;
          },
          overridePath: "B2BRequests/Logs",
        },
      }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
      filters={b2BRequestLogFilters}
    >
      <ListFilterCleaner>
        <Datagrid bulkActionButtons={false}>
          <TextField sortable={false} label="ID" source="b2BRequestLogId" />
          <TextField
            sortable={false}
            label="API request ID"
            source="b2BRequestId"
          />
          <TimestampDateField
            sortable={false}
            label="Date"
            source="timestamp"
          />
          <TextField sortable={false} source="info" />
          <BooleanField sortable={false} source="isError" />
        </Datagrid>
      </ListFilterCleaner>
    </List>
  );
};
